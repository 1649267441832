// import { escape } from "lodash";

export function usernameValidator() {
  return async (rule, inputString) => {
    if (/\s/g.test(inputString)) {
      throw new Error("用户名不能包含空格");
    };
    if (/[\u4e00-\u9fa5]/.test(inputString)) {
      throw new Error("用户名不能包含中文");
    };
    return true;
  };
};