import React from "react";
import { BankOutlined } from "@ant-design/icons";

export function fromatOrganizationTreeList(originTreeList, userCountMapping) {
  return originTreeList.map((everyOrganizationNode) => {
    const { org_id, org_name } = everyOrganizationNode;
    const userCount = userCountMapping[org_id] || 0;
    const { children, ...resetOrganizationNode } = everyOrganizationNode;
    let title: any = `${org_name} [${userCount}人]`;
    if (resetOrganizationNode.disabled === true) {
      resetOrganizationNode.disabledRename = resetOrganizationNode.disabled;
      delete resetOrganizationNode.disabled;
      title = <s>{`${org_name} [${userCount}人]`}</s>;
    }
    if (children instanceof Array) {
      return { key: `org:${org_id}`, type: "org", title, icon: (<BankOutlined />), children: fromatOrganizationTreeList(children, userCountMapping), ...resetOrganizationNode };
    };
    return { key: `org:${org_id}`, type: "org", title, icon: (<BankOutlined />), ...resetOrganizationNode };
  });
};