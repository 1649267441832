import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { request } from "@/utils/request";

@injectable()
export class ModuleService {

    public orgModules: any[] = null;

    constructor(
    ) { };

    /** 获取企业分配到的模块 **/
    public async getAssignModuleWithOrganization(org_id) {
        try {
            this.orgModules = await request({
                method: "GET",
                url: "/api/gmecamp/module/getAssignModuleWithOrganization",
                params: { organization_id: org_id }
            });
        } catch (error) {
            throw error;
        };
    };
    public async assginModuleToOrganization(org_id, modules) {
        try {
            await request({
                method: "POST",
                url: "/api/gmecamp/module/assginModuleToOrganization",
                data: { organization_id: org_id, modules }
            });
        } catch (error) {
            throw error;
        };
    };

};