/* eslint-disable react/prop-types */
import { Select } from "antd";
import propTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { request } from "@/utils/request";

export function SelectSubSystem(props) {

  const [subSystemList, setCategoryList] = useState([]);

  useEffect(() => {
    requestAnimationFrame(async () => {
      const result = await request({
        method: "GET",
        url: "/api/gmecamp/subsystem/getSubSystemList"
      });
      setCategoryList([{ subsystem_id: null, subsystem_name: "未指定" }, ...result]);
    });
  }, []);

  return (
    <Select {...props} placeholder="请选择关联的系统">
      {subSystemList.map(({ subsystem_id, subsystem_name }) => {
        return (
          //@ts-ignore
          <Select.Option key={subsystem_id} value={subsystem_id}>
            {subsystem_name}
          </Select.Option>
        )
      })}
    </Select>
  )
};


SelectSubSystem.propTypes = {
  disabled: propTypes.bool
};

SelectSubSystem.defaultProps = {

};