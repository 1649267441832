/* eslint-disable react/prop-types */
import React from "react";
import { Flex, Collapse, Button } from "antd";
import { SettingOutlined } from "@ant-design/icons";

import { PageContainer } from "@/components/PageContainer";

import { CustmerConfigBlock } from "@/pages/OrganizationUserPages/CustmerSettingPage/components/CustmerConfigBlock";
import { BasicInfoBlock } from "@/pages/OrganizationUserPages/CustmerSettingPage/components/BasicInfoBlock";
import { ModifyPasswordLayer } from "@/components/ModifyPasswordLayer";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { UserInfoService } from "@/services/framework/UserInfoService";

export function CustmerSettingPage(props) {
  const userManagerService: UserManagerService = useDependencieEveryTime(UserManagerService);
  const userInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;
  return (
    <PageContainer>
      <Flex gap={20} vertical>
        {/* <Collapse
          activeKey={["基本信息"]}
          items={[{
            key: "基本信息",
            label: "基本信息",
            showArrow: false,
            //@ts-ignore
            extra: (<SettingOutlined />),
            //@ts-ignore
            children: (<BasicInfoBlock />),
          }]}
        /> */}
        <Button size="large" type="primary" style={{ marginLeft: "auto" }}
          onClick={() => userManagerService.modifyPasswordController.open(userInfo)} >
          修改用户密码
        </Button>
        <ModifyPasswordLayer
          controller={userManagerService.modifyPasswordController}
          onSubmit={(form, organizationUserInfo) => userManagerService.modifyPassword(organizationUserInfo, form)}
        />
        <Collapse
          activeKey={["个性化配置"]}
          items={[{
            key: "个性化配置",
            label: "个性化配置",
            showArrow: false,
            //@ts-ignore
            extra: (<SettingOutlined />),
            //@ts-ignore
            children: (<CustmerConfigBlock />),
          }]}
        />
      </Flex>
    </PageContainer >
  )
};


CustmerSettingPage.propTypes = {

};

CustmerSettingPage.defaultProps = {

};