/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { SelectUserLevel } from "@/components/OrganizationUserComponent/SelectUserLevel";
import { SelectDepartment } from "@/components/OrganizationUserComponent/SelectDepartment";

import { usernameValidator } from "@/utils/usernameValidator";
import { validatorPassword } from "@/utils/confirmPasswordRule";
import { FloatController } from "@/utils/FloatController";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";

export function AddUserLayer(props) {
  const { organizationInfo, departmentInfo, controller, onAddUserWithOrg, onAddUser } = props;

  const [status, setStatus] = useState(false);

  const [searchParams] = useSearchParams();
  const query_org_id = searchParams.get("org_id");
  const query_dept_id = searchParams.get("dept_id");

  const orgDeptTreeDataService: OrgDeptTreeDataService = useDependencieEveryTime(OrgDeptTreeDataService);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => { setStatus(true) });
    controller.registryConfirmHandler(() => { setStatus(false) });
    controller.registryCancelHandler(() => { setStatus(false) });
    controller.registryCloseHandler(() => { setStatus(false) });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  /**
   * 根据不同类型的查询参数得到的核心业务参数应该是不同的 
   * 如果查询字符串是org_id,那就要按照在企业下添加用户的方式来获取业务参数
   * 如果查询字符串是dept_id,那就要按照基于部门添加用户的方式来获取业务参数
   * **/
  const coreParams = useMemo(() => {
    if (query_dept_id) {
      if (!departmentInfo) {
        return false;
      };
      const org_id = Number(get(departmentInfo, "relation_organization.org_id"));
      const dept_id = Number(get(departmentInfo, "dept_id"));
      return { org_id, dept_id };
    };
    if (query_org_id) {
      if (!organizationInfo) {
        return false
      };
      return { org_id: organizationInfo.org_id, dept_id: -1 }
    };
    return false;
  }, [query_org_id, organizationInfo, query_dept_id, departmentInfo]);

  /** 
   * 如果查询字符串的内容是org_id,就表示要调用在企业下添加用户的接口
   * 如果查询字符串的内容是dept_id,就表示要调用在指定部门下添加用户的接口
   * **/
  const handleSubmit = useCallback(async (form) => {
    if (query_org_id) {
      await onAddUserWithOrg(organizationInfo, form);
    };
    if (query_dept_id) {
      await onAddUser(departmentInfo, form);
    };
    await orgDeptTreeDataService.getTreeData();

    return false;

  }, [query_org_id, organizationInfo, onAddUserWithOrg, query_dept_id, departmentInfo, onAddUser]);

  if (!coreParams) {
    return null;
  };

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="新增用户" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="用户账号" name="username" rules={[{ required: true }, { validator: usernameValidator() }]}>
          <Input allowClear placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item label="关联部门" name="dept_id" initialValue={coreParams.dept_id}>
          <SelectDepartment disabled={Boolean(query_dept_id)} org_id={coreParams.org_id} />
        </Form.Item>
        <Form.Item label="权限等级" name="role" rules={[{ required: true }]} initialValue="user">
          <SelectUserLevel />
        </Form.Item>
        <Form.Item label="登陆密码" name="password" rules={[{ required: true }, { validator: validatorPassword() }]}>
          <Input.Password allowClear placeholder="请输入登陆密码" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => handleSubmit(form)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AddUserLayer.propTypes = {
  organizationInfo: propTypes.instanceOf(Object),
  departmentInfo: propTypes.instanceOf(Object),
  controller: propTypes.instanceOf(FloatController),
  onAddUserWithOrg: propTypes.func,
  onAddUser: propTypes.func
};

AddUserLayer.defaultProps = {
  onAddUserWithOrg() { },
  onAddUser() { }
};