/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Modal, Form, Input, Button, Flex } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import { SelectSubSystem } from "@/components/SuperUserComponent/SelectSubSystem";
import { FloatController } from "@/utils/FloatController";

import { IconSel } from "@/components/IconSel";

export function UpdateGroupFloatLayer(props) {
  const { controller, selectSubSystemId, onSubmit } = props;

  const [status, setStatus] = useState(false);
  const [groupInfo, setGroupInfo]: any[] = useState({});

  const [form] = Form.useForm();

  const iconSelController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputOrganizationInfo) => {
      setGroupInfo(inputOrganizationInfo);
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setGroupInfo({});
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setGroupInfo({});
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setGroupInfo({});
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  const saveIcon = function (xxx, iconName) {
    form.setFieldsValue({
      iconName
    });
    iconSelController.close();
  }

  useEffect(() => {
    form.setFieldsValue({
      group_name: groupInfo.group_name,
      iconName: groupInfo.iconName,
      subsystem_id: selectSubSystemId,
    })
  }, [groupInfo, selectSubSystemId]);

  return (
    <Flex gap={20} vertical flex={1}>
      <Modal zIndex={9999} width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="更新分组信息" onCancel={handleCancel}>
        <Form layout="vertical" form={form}>
          <Form.Item label="分组的名称" name="group_name" rules={[{ required: true }]} >
            <Input allowClear placeholder="请输入分组的名称" />
          </Form.Item>
          <Form.Item label="所属子系统" name="subsystem_id" rules={[{ required: false }]}>
            <SelectSubSystem disabled />
          </Form.Item>
          <Form.Item label="图标" name="iconName" rules={[{ required: false }]} >
            <Input allowClear placeholder="图标" />
          </Form.Item>
          <Form.Item>
            <Button type='primary' onClick={() => iconSelController.open()}>选择图标</Button>
          </Form.Item>
          <Form.Item shouldUpdate>
            {(form) => {
              return <Button block type="primary" onClick={() => onSubmit(form, groupInfo)}>确认提交</Button>
            }}
          </Form.Item>
        </Form>
      </Modal>
      <IconSel controller={iconSelController} onOk={saveIcon}></IconSel>
    </Flex>
  )
};


UpdateGroupFloatLayer.propTypes = {
  selectSubSystemId: propTypes.number,
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateGroupFloatLayer.defaultProps = {
  onSubmit() { }
};