/* eslint-disable react/prop-types */
import Scrollbar from "react-scrollbars-custom";
import React, { useMemo, useEffect } from "react";
import { ConfigProvider, Layout, Flex, Space } from "antd";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { UserInfoBlock } from "@/components/UserInfoBlock";

import { SubscribeNotification } from "@/layouts/OrganizationUserLayout/components/SubscribeNotification";
import { SubSystemNavigation } from "@/layouts/OrganizationUserLayout/components/SubSystemNavigation";
import { SystemMenuSiderBar } from "@/layouts/OrganizationUserLayout/components/SystemMenuSiderBar";
import { LogoBlock } from "@/layouts/OrganizationUserLayout/components/LogoBlock";

import { UserInfoService } from "@/services/framework/UserInfoService";
import { ThemeService } from "@/services/framework/ThemeService";
import { MenuService } from "@/services/framework/MenuService";

import css from "./style.module.less";
import sider_bg from "./assets/sider_bg.png";

import { DisplayDate } from "./components/DisplayDate";

export function OrganizationUserLayout(props) {

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  const menuService: MenuService = useDependencieEveryTime(MenuService);

  const navigate = useNavigate();
  const location = useLocation();

  /** 跳转到第一个系统的第一个模块 **/
  useEffect(() => {
    requestAnimationFrame(async () => {
      if (location.pathname === "/") {
        const firstRoutePath = await menuService.getFirstLinkWithSubSystemId();
        navigate(firstRoutePath);
      };
    });
  }, [menuService, navigate]);

  return (
    <ConfigProvider theme={{ components: themeService.getThemeConfig().layoutThemeConfig }}>
      <Layout style={{ height: "100%" }}>
        <Layout.Sider collapsible width={208} style={{ ...themeService.getThemeConfig().siderThemeConfig, backgroundSize: "100% 100%", backgroundImage: `url(${sider_bg})` }} >
          <Scrollbar
            thumbYProps={{
              renderer: (props) => {
                const { elementRef, style, ...restProps } = props;
                return <div {...restProps} ref={elementRef} className={css.scroll} />;
              },
            }}
          >
            <LogoBlock />
            <Flex vertical>
              <SystemMenuSiderBar />
            </Flex>
          </Scrollbar>
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{ ...themeService.getThemeConfig().headerThemeConfig }}>
            <Flex justify="space-between" align="center">
              <SubSystemNavigation />
              <Space size={20} align="center">
                <DisplayDate />
                <SubscribeNotification />
                <UserInfoBlock />
              </Space>
            </Flex>
          </Layout.Header>
          <Layout.Content className={css.contentContainer}>
            <div className={css.contentBlock} style={{ background: "white", boxSizing: "content-box", boxShadow: "0px 0px 20px rgba(0,0,0,0.1)" }}>
              <Outlet />
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
};


OrganizationUserLayout.propTypes = {

};

OrganizationUserLayout.defaultProps = {
  onChangeSubSystem() { }
};