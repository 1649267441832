import path from "path";

export function flatMenuWithAssignModule(userAssignModules) {
  const allAssignModule = {};
  userAssignModules.forEach(({ subsystem, href, name }) => {
    const { subsystem_id } = subsystem;
    const routeKeyName = path.join("/", String(subsystem_id), href);
    if (allAssignModule[subsystem_id] instanceof Array) {
      allAssignModule[subsystem_id].push({ key: routeKeyName, label: name });
    } else {
      allAssignModule[subsystem_id] = [];
      allAssignModule[subsystem_id].push({ key: routeKeyName, label: name });
    };
  });
  return allAssignModule;
};