
const SUPER = 'super';
const DEV = 'dev';
const OPER = 'oper';
const ADMIN = 'admin';
const USER = 'user';


export function isSuper(role) {
    return role === SUPER;
}

export function isDev(role) {
    return role === DEV;
}

export function isOper(role) {
    return role === OPER;
}

export function isGaeaRole(role) {
    return [SUPER, DEV, OPER].indexOf(role) > -1;
}

export function aboveDev(role) {
    return [SUPER, DEV].indexOf(role) > -1;
}

export function aboveOper(role) {
    return [SUPER, OPER].indexOf(role) > -1;
}

export function isAdmin(role) {
    return role === ADMIN;
}

export function adoveAdmin(role) {
    return [SUPER, DEV, OPER, ADMIN].indexOf(role) > -1;
}

export function isUser(role) {
    return role === USER;
}