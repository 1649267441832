/* eslint-disable react/prop-types */
import React from "react";
import { notification } from "antd";
import { createRoot } from "react-dom/client";

import './styles.css'; // 引入自定义样式

import { IOCContainer } from "@/commons/IOCContainer";
import { IOCContextProvider } from "@/commons/ReactIOCContext";
import { CampApplication } from "@/commons/CampApplication";

notification.config({ top: 80 });

export function Application(props) {
  return (
    <IOCContextProvider IOCContainer={IOCContainer}>
      <CampApplication />
    </IOCContextProvider>
  )
};

createRoot(document.getElementById("root")).render(<Application />);