import { get } from "dot-prop";
import { message } from "antd";
import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { ModuleService } from "@/services/framework/ModuleService";

import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";


@injectable()
export class DepartmentManagerService {

  public addDepartmentController = new FloatController();

  public updateDepartmentController = new FloatController();

  public deleteDepartmentController = new FloatController();

  public assignUserController = new FloatController();

  constructor(
    @inject(OrgDeptTreeDataService) private readonly $OrgDeptTreeDataService: OrgDeptTreeDataService,
    @inject(UserManagerService) private readonly $UserManagerService: UserManagerService,
    @inject(ModuleService) private readonly $ModuleService: ModuleService,
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  public departmentInfo: any = null;

  public async clearDepartmentInfo() {
    // this.departmentInfo = null;
    await this.$GlobalUIRender.executeRender();
  };

  /** 获取到部门的信息 **/
  public async getDepartmentInfo(dept_id: number) {
    try {
      await this.clearDepartmentInfo();
      const departmentInfo = await request({
        method: "GET",
        url: "/api/gmecamp/department/getDepartmentInfo",
        params: { dept_id }
      });
      this.departmentInfo = departmentInfo;
      await this.$GlobalUIRender.executeRender();
    } catch (error) {
      message.error({ key: "getDepartmentInfo", content: error.message, duration: 3 });
    };
  };

  /** 新增部门 **/
  public async addDepartment(contextInfo, form) {
    try {
      const { relation_organization } = contextInfo;
      const { dept_name, parent_dept_id, users } = await form.validateFields();
      let finally_parent_dept_id = get(contextInfo, "parent_department.dept_id");
      if (parent_dept_id === -1) {
        finally_parent_dept_id = undefined;
      } else {
        finally_parent_dept_id = parent_dept_id;
      };
      await request({
        method: "POST",
        url: "/api/gmecamp/department/addDepartment",
        data: { dept_name, parent_dept_id: finally_parent_dept_id, org_id: relation_organization.org_id, users }
      });
      await this.addDepartmentController.close();
      await this.$OrgDeptTreeDataService.getTreeData();
    } catch (error) {
      message.error({ key: "addDepartment", content: error.message, duration: 3 });
    };
  };

  /** 基于orgId新增部门 **/
  public async addDepartmentWithOrg(contextInfo, form) {
    try {
      const { org_id } = contextInfo;
      const { dept_name, users } = await form.validateFields();
      await request({
        method: "POST",
        url: "/api/gmecamp/department/addDepartment",
        data: { dept_name, parent_dept_id: undefined, org_id, users }
      });
      await this.addDepartmentController.close();
      await this.$OrgDeptTreeDataService.getTreeData();
      await this.$UserManagerService.getUserListWithOrganization(org_id);
    } catch (error) {
      message.error({ key: "addDepartmentWithOrg", content: error.message, duration: 3 });
    };
  };

  /** 更新部门 **/
  public async updateDepartment(departmentContextInfo, form) {
    try {
      const { dept_id } = departmentContextInfo;
      const { dept_name, parent_dept_id, org_id } = await form.validateFields();
      await request({
        method: "POST",
        url: "/api/gmecamp/department/updateDepartment",
        data: {
          dept_id, dept_name,
          org_id: org_id === -1 ? null : org_id,
          parent_dept_id: parent_dept_id === -1 ? null : parent_dept_id
        }
      });
      await this.updateDepartmentController.close();
      await this.getDepartmentInfo(dept_id);
      await this.$OrgDeptTreeDataService.getTreeData();
      await this.$ModuleService.getAssignModuleWithOrganization(org_id);
      await this.$UserManagerService.getUserListWithDepartment(dept_id);
    } catch (error) {
      message.error({ key: "updateDepartment", content: error.message, duration: 3 });
    };
  };

  /** 分配用户 **/
  public async assignUser(departmentContextInfo, form) {
    try {
      const { dept_id } = departmentContextInfo;
      const { users } = await form.validateFields();
      await request({
        method: "POST",
        url: "/api/gmecamp/department/assignUserToDepartment",
        data: { dept_id, users }
      });
      await this.assignUserController.close();
      await this.$OrgDeptTreeDataService.getTreeData();
      await this.$UserManagerService.getUserListWithDepartment(dept_id);
    } catch (error) {
      message.error({ key: "assignUser", content: error.message, duration: 3 });
    };
  };

  /** 删除部门 **/
  public async deleteDepartment(contextInfo) {
    try {
      const { dept_id } = contextInfo;
      await request({
        method: "POST",
        url: "/api/gmecamp/department/deleteDepartment",
        data: { dept_id }
      });
      await this.deleteDepartmentController.close();
      await this.$OrgDeptTreeDataService.getTreeData();
    } catch (error) {
      message.error({ key: "deleteDepartment", content: error.message, duration: 3 });
    };
  };

};