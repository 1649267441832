// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JRQt26YH87PfKKyGFnEo {
  width: 100%;
  height: 100px;
  text-align: center;
  position: relative;
}
.IhRkwlQNh_4hkCjczEsQ {
  background: white;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  background-size: cover;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/OrganizationUserLayout/components/LogoBlock/style.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACF;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,SAAA;EAAS,YAAA;EACT,UAAA;EAAU,WAAA;EACV,sBAAA;AAEF","sourcesContent":[".container {\n  width: 100%;\n  height: 100px;\n  text-align: center;\n  position: relative;\n}\n\n.logo {\n  background: white;\n  position: absolute;\n  top:10px;bottom:10px;\n  left:10px;right:10px;\n  background-size: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JRQt26YH87PfKKyGFnEo`,
	"logo": `IhRkwlQNh_4hkCjczEsQ`
};
export default ___CSS_LOADER_EXPORT___;
