/* eslint-disable react/prop-types */
import url from "url";
import { ConfigProvider, Menu, message } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import Icon from '@ant-design/icons'
import * as icons from '@ant-design/icons'

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { ThemeService } from "@/services/framework/ThemeService";
import { MenuService } from "@/services/framework/MenuService";

export function SystemMenuSiderBar(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const [userMenu, setUserMenu] = useState([]);

  const subSystemService: SubSystemService = useDependencieEveryTime(SubSystemService);
  const subSystemId = subSystemService.getSubSystemId();

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);
  const menuService: MenuService = useDependencieEveryTime(MenuService);

  useEffect(() => {
    requestAnimationFrame(async () => {
      try {
        setUserMenu([]);
        const menuListWithCurrentSubSystem = await menuService.getMenuListWithSubSystemId();
        let tempUserMenu = [];
        menuListWithCurrentSubSystem.forEach((group) => {
          let tempGroup = { ...group, icon: <Icon component={icons[group.icon] || ''}></Icon> }
          if (group.children) {
            tempGroup.children = [];
            group.children.forEach(module => {
              tempGroup.children.push({ ...module, icon: <Icon component={icons[module.icon] || ''}></Icon> })
            })
          }
          tempUserMenu.push(tempGroup);
        })
        setUserMenu(tempUserMenu);
      } catch (error) {
        message.error({ key: "SystemMenuSiderBar", content: error.message, duration: 3 });
      };
    });
  }, [subSystemId]);

  const defaultOpenKeys = useMemo(() => {
    return userMenu.map(({ key }) => key);
  }, [userMenu]);

  const handleClick = useCallback(async (event) => {
    const { key } = event;
    const { protocol } = url.parse(key);
    if (protocol === "http:" || protocol === "https:") {
      window.location.href = key;
      return false;
    };
    navigate(key, { replace: false });
  }, [navigate]);

  if (!defaultOpenKeys.length) {
    return null;
  };

  if (!userMenu.length) {
    return null;
  };
  return (
    <ConfigProvider theme={{ components: themeService.getThemeConfig().systemMenuThemeConfig }}>
      <Menu
        theme={themeService.theme}
        mode="inline"
        items={userMenu}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[location.pathname]}
        onClick={handleClick}
      />
    </ConfigProvider>
  )
};


SystemMenuSiderBar.propTypes = {

};

SystemMenuSiderBar.defaultProps = {

};