/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Form, Button, message } from "antd";

import { SelectTheme } from "@/pages/OrganizationUserPages/CustmerSettingPage/elements/SelectTheme";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { UserInfoService } from "@/services/framework/UserInfoService";
import { ThemeService } from "@/services/framework/ThemeService";

import { request } from "@/utils/request";

export function CustmerConfigBlock(props) {

  const [form] = Form.useForm();

  const userInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  const handleSubmit = useCallback(async () => {
    try {
      message.loading({ key: "handleSubmit", content: "正在提交数据...", duration: 0 });
      const { theme } = await form.validateFields();
      await request({
        method: "POST",
        url: "/api/gmecamp/user/updateTheme",
        data: { user_id: userInfo.user_id, theme }
      });
      message.success({ key: "handleSubmit", content: "更新成功!", duration: 3 });
      window.location.reload();
    } catch (error) {
      message.error({ key: "handleSubmit", content: error.message, duration: 3 });
    };
  }, [form, userInfo]);

  if (!themeService.theme) {
    return null;
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item name="theme" label="颜色主题" initialValue={themeService.theme}>
        <SelectTheme />
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" onClick={handleSubmit}>
          更新个性化配置
        </Button>
      </Form.Item>
    </Form>
  )
};


CustmerConfigBlock.propTypes = {


};
CustmerConfigBlock.defaultProps = {


};