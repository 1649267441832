/* eslint-disable react/prop-types */
import url from "url";
import { Flex, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { FullGroupList } from "./components/FullGroupList";
import { SubSystemTreeData } from "./components/SubSystemTreeData";
import { SubSystyemInfomation } from "./components/SubSystyemInfomation";
import { PageContainer } from "@/components/PageContainer";


import { request } from "@/utils/request";

export function SubSystemConfigPage(props) {

  const location = useLocation();
  const navigate = useNavigate();

  const [groupList, setGroupList] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [subsystemInfomation, setSubSystemInfo] = useState(null);
  const { subsystem_id } = useMemo(() => url.parse(location.search, true).query, [location]);

  /** 获取列表 **/
  const handleFetchSubSystemList = useCallback(async () => {
    const subsystemList = await request({
      method: "GET",
      url: "/api/gmecamp/subsystem/getSubSystemList"
    });
    const filterAndSortable = subsystemList.map(({ subsystem_id, subsystem_name, subsystem_pos }) => {
      return { key: subsystem_id, title: subsystem_name, subsystem_pos };
    }).sort((prev, next) => {
      return prev.subsystem_pos - next.subsystem_pos;
    });
    setDataSource(filterAndSortable);
  }, [location]);

  useEffect(() => { handleFetchSubSystemList() }, [handleFetchSubSystemList]);

  /** 子系统排序 **/
  const handleSubSystemSort = useCallback(async (sortableDataSource) => {
    try {
      await request({
        method: "POST",
        url: "/api/gmecamp/subsystem/updateSubsystemSort",
        data: sortableDataSource
      });
      await handleFetchSubSystemList();
      message.success({ key: "handleSubSystemSort", content: "排序成功!!!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleSubSystemSort", content: error.message, duration: 3 });
    };
  }, [handleFetchSubSystemList]);

  /** 选择子系统 **/
  const handleSelectSubSystem = useCallback((subsystem_id) => {
    const linkhref = url.format({
      pathname: location.pathname,
      query: { subsystem_id }
    });
    navigate(linkhref);
  }, [location, navigate]);

  /** 加载子系统信息 **/
  const handleFetchSubSystemInfo = useCallback(async () => {
    if (!dataSource) {
      return false;
    };
    if (!subsystem_id) {
      const linkhref = url.format({
        pathname: location.pathname,
        query: { subsystem_id: dataSource[0].key }
      });
      navigate(linkhref);
      return false;
    };
    try {
      const subsystemInfo = await request({
        method: "GET",
        url: "/api/gmecamp/subsystem/getSubSystemInfo",
        params: { subsystem_id }
      });
      setSubSystemInfo(subsystemInfo);
    } catch (error) {
      message.error({ key: "handleSelectSubSystem", content: error.message, duration: 3 });
    };
  }, [dataSource, subsystem_id, navigate]);

  /** 加载分组列表 **/
  const handelLoadGroupList = useCallback(async () => {
    if (!dataSource) {
      return false;
    };
    try {
      const fetchGroupList = await request({
        method: "GET",
        url: "/api/gemcamp/group/getGroupList",
        params: { subsystem_id }
      });
      setGroupList(fetchGroupList);
    } catch (error) {
      message.error({ key: "handelLoadGroupList", content: error.message, duration: 3 });
    };
  }, [dataSource, subsystem_id]);

  const handleGroupSort = useCallback(async (sortableResult) => {
    try {
      await request({
        method: "POST",
        url: "/api/gemcamp/group/updateGroupSort",
        data: sortableResult
      });
      await handelLoadGroupList();
      message.success({ key: "handleGroupSort", content: "排序成功!!!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleGroupSort", content: error.message, duration: 3 });
    };
  }, [handelLoadGroupList]);

  useEffect(() => {
    handleFetchSubSystemInfo();
    handelLoadGroupList();
  }, [handleFetchSubSystemInfo, handelLoadGroupList]);

  return (
    <PageContainer>
      <Flex gap={20}>
        <SubSystemTreeData
          dataSource={dataSource}
          onSubSystemSort={handleSubSystemSort}
          onSelectSubSystem={handleSelectSubSystem}
          onReloadSubSystemList={handleFetchSubSystemList}
        />
        <Flex vertical gap={20} flex={1}>
          <SubSystyemInfomation
            subsystemInfomation={subsystemInfomation}
            onReloadSubSystemList={handleFetchSubSystemList}
          />
          <FullGroupList
            dataSource={groupList}
            onGroupSort={handleGroupSort}
            onReLoadGroupList={handelLoadGroupList}
          />
        </Flex>
      </Flex>
    </PageContainer>
  )
};


SubSystemConfigPage.propTypes = {

};

SubSystemConfigPage.defaultProps = {

};