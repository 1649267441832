import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { UserInfoService } from "@/services/framework/UserInfoService";
import { request } from "@/utils/request";

@injectable()
export class OrganizationService {
  //当前用户企业

  public organizationInfoData: any = null;

  public organizationModules: any[] = null;

  constructor(
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender,
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService
  ) { };

  /** 获取企业的基本信息 **/
  public async getOrganizationInfo() {
    try {
      const organizationInfoData = await request({
        method: "GET",
        url: "/api/gmecamp/org/getOrganizationInfo",
        params: { org_id: this.$UserInfoService.userInfo.org_id }
      });
      this.organizationInfoData = organizationInfoData;
      this.$GlobalUIRender.executeRender();
    } catch (error) {
      throw error;
    };
  };

  /** 获取企业分配到的模块 **/
  public async getAssignModuleWithOrganization() {
    try {
      const organizationModules = await request({
        method: "GET",
        url: "/api/gmecamp/module/getAssignModuleWithOrganization",
        params: { organization_id: this.$UserInfoService.userInfo.org_id }
      });
      this.organizationModules = organizationModules;
      this.$GlobalUIRender.executeRender();
    } catch (error) {
      throw error;
    };
  };

};