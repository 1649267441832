/* eslint-disable react/prop-types */
import url from "url";
import propTypes from "prop-types";
import { arrayMove } from "@dnd-kit/sortable";
import { PlusOutlined } from "@ant-design/icons";
import React, { useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Flex, Table, Space, Divider, Button, message } from "antd";

import { DragableTable, DragableRow } from "@/components/DragableSort";

import { AddGroupFloatLayer } from "../../floatlayers/AddGroupFloatLayer";
import { UpdateGroupFloatLayer } from "../../floatlayers/UpdateGroupFloatLayer";
import { DeleteGroupFloatLayer } from "../../floatlayers/DeleteGroupFloatLayer";

import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";

export function FullGroupList(props) {
  const { dataSource, onGroupSort, onReLoadGroupList } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { subsystem_id } = useMemo(() => url.parse(location.search, true).query, [location]);

  /** 新增分组 **/
  const addGroupController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleAddGroup = useCallback(async (form) => {
    try {
      const fields = await form.validateFields();
      message.loading({ key: "handleAddGroup", content: "正在添加分组信息", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gemcamp/group/addGroup",
        data: { ...fields }
      });
      message.success({ key: "handleAddGroup", content: "分组信息添加成功", duration: 3 });
      await addGroupController.close();
      await onReLoadGroupList();
    } catch (error) {
      message.error({ key: "handleAddGroup", content: error.message, duration: 3 });
    };
  }, [addGroupController, onReLoadGroupList]);

  /** 更新分组信息 **/
  const updateGroupController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleUpdateGroup = useCallback(async (form, record) => {
    try {
      const fields = await form.validateFields();
      message.loading({ key: "handleUpdateGroup", content: "正在更新分组信息", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gemcamp/group/updateGroup",
        data: { module_group_id: record.module_group_id, ...fields }
      });
      message.success({ key: "handleUpdateGroup", content: "分组信息更新成功", duration: 3 });
      await updateGroupController.close();
      await onReLoadGroupList();
    } catch (error) {
      message.error({ key: "handleUpdateGroup", content: error.message, duration: 3 });
    };
  }, [updateGroupController, onReLoadGroupList]);

  /** 删除分组 **/
  const deleteGroupController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleDeleteGroup = useCallback(async (record) => {
    try {
      message.loading({ key: "handleDeleteGroup", content: "正在删除分组信息", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gemcamp/group/deleteGroup",
        data: { module_group_id: record.module_group_id }
      });
      message.success({ key: "handleDeleteGroup", content: "分组信息删除成功", duration: 3 });
      await deleteGroupController.close();
      await onReLoadGroupList();
    } catch (error) {
      message.error({ key: "handleDeleteGroup", content: error.message, duration: 3 });
    };
  }, [deleteGroupController, onReLoadGroupList]);

  const columns: any[] = useMemo(() => {
    return [{
      key: "module_group_id",
      align: "center",
      dataIndex: "module_group_id",
      title: "分组ID"
    }, {
      key: "group_pos",
      align: "center",
      title: "排序",
      dataIndex: "group_pos"
    }, {
      key: "group_name",
      align: "center",
      title: "分组名称",
      dataIndex: "group_name"
    }, {
      key: "subsystem_name",
      align: "center",
      title: "关联子系统",
      dataIndex: "subsystem_name"
    }, {
      key: "action",
      align: "center",
      title: "操作",
      render(record) {
        return (
          <Space split={(<Divider type="vertical" />)}>
            <Button type="default" onClick={() => navigate(`/main/${subsystem_id}/${record.module_group_id}/module`)}>业务模块</Button>
            <Button type="primary" onClick={() => updateGroupController.open(record)}>编辑分组</Button>
            <Button type="primary" danger onClick={() => deleteGroupController.open(record)}>删除分组</Button>
          </Space>
        )
      }
    }];
  }, [subsystem_id]);

  const sortableSataSource = useMemo(() => {
    return (dataSource || []).sort((prev, next) => {
      return prev.group_pos - next.group_pos;
    });
  }, [dataSource]);

  const handleDragEnd = useCallback(async ({ active, over }) => {
    if (!over) {
      return false;
    };
    if (!active) {
      return false;
    };
    if (active.id === over.id) {
      return false;
    };
    const prevDataSource = JSON.parse(JSON.stringify(dataSource));
    const activeIndex = prevDataSource.findIndex((item) => item.module_group_id === active.id);
    const overIndex = prevDataSource.findIndex((item) => item.module_group_id === over.id);
    const arrayMoveResult = arrayMove(prevDataSource, activeIndex, overIndex);
    /** 重新计算排序 **/
    const sortableResult = arrayMoveResult.map((everyItemInfo, index) => {
      const cloneItemInfo = JSON.parse(JSON.stringify(everyItemInfo));
      return { module_group_id: cloneItemInfo.module_group_id, group_pos: index + 1 };
    });
    await onGroupSort(sortableResult);
  }, [dataSource, onGroupSort]);

  return (
    <Flex gap={20} vertical flex={1}>
      <Flex justify="space-between" align="center">
        <Flex>分组详情</Flex>
        <Flex>
          <Button icon={(<PlusOutlined />)} type="primary" onClick={() => addGroupController.open()}>添加分组</Button>
        </Flex>
      </Flex>
      <Flex vertical flex={1}>
        <DragableTable items={(dataSource || []).map((everyItem) => everyItem.module_group_id)} onDragEnd={handleDragEnd}>
          <Table
            bordered
            columns={columns}
            rowKey="module_group_id"
            dataSource={sortableSataSource}
            components={{ body: { row: DragableRow } }}
          />
        </DragableTable>
      </Flex>
      <AddGroupFloatLayer selectSubSystemId={Number(subsystem_id)} controller={addGroupController} onSubmit={handleAddGroup} />
      <UpdateGroupFloatLayer selectSubSystemId={Number(subsystem_id)} controller={updateGroupController} onSubmit={handleUpdateGroup} />
      <DeleteGroupFloatLayer selectSubSystemId={Number(subsystem_id)} controller={deleteGroupController} onSubmit={handleDeleteGroup} />
    </Flex>
  )
};


FullGroupList.propTypes = {
  dataSource: propTypes.array,
  onGroupSort: propTypes.func,
  onUpdateGroup: propTypes.func,
  onDeleteGroup: propTypes.func,
  onReLoadGroupList: propTypes.func
};

FullGroupList.defaultProps = {
  dataSource: [],
  onGroupSort() { },
  onUpdateGroup() { },
  onDeleteGroup() { },
  onReLoadGroupList() { }
};