

export function confirmPasswordRule(form) {
  return async (rule, value) => {
    if (!value) {
      return false;
    };
    const originPassword = form.getFieldValue("password");
    if (originPassword === value) {
      return true;
    };
    throw new Error("两次输入的密码不一致");
  };
};

export function validatorPassword() {
  return async (rule, value) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[\x21-\x7D]{8,}$/;

    if (regex.test(value)) {
      return true;
    };
    throw new Error("必须有大，小写字母和数字，长度不小于8个字符。");
  }
}