/* eslint-disable react/prop-types */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { LoginPage } from "@/pages/LoginPage";

export function LoginPageEntry(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={(<LoginPage />)} />
      </Routes>
    </BrowserRouter>
  )
};


LoginPage.propTypes = {

};

LoginPage.defaultProps = {

};