import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";

import { fromatOrganizationTreeList } from "@/utils/fromatOrganizationTreeList";
import { fromatDepartmentTreeList } from "@/utils/fromatDepartmentTreeList";
import { request } from "@/utils/request";
import { isGaeaRole } from "@/utils/checkRole";

/**
 * 这个类是把原来用来管理公司的业务和管理部门的业务聚合在一起的组合类
 * 原本公司和部门的树形结构是相互独立的，但是实际需求需要把这两个树形结构组合起来展示为一个树
 * **/
@injectable()
export class OrgDeptTreeDataService {

  /** 用于展示的树形结构 **/
  public treeData: any[] | null = null;

  constructor(
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService,
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  /**
   * 辅助步骤1的方法
   * 基于用户所在的机构
   * 获取当前机构以及其嫡系机构下用户的数量
   * **/
  private async getOrgUserCount(orgId) {
    const { org_id } = this.$UserInfoService.userInfo;
    orgId = orgId || org_id;
    const userCountMapping = await request({
      method: "GET",
      url: "/api/gmecamp/org/getAllOrganizationStaffCount",
      params: { org_id: orgId }
    });
    return userCountMapping;
  };

  /**
   * 单个步骤1
   * 用于业务的组合,把当前用户企业下所有的相关的企业都获取到,作为根节点使用
   * **/
  private async getCompanyList() {
    let userCountMapping = {};
    // const userCountMapping = await this.getOrgUserCount();
    const { org_id, role } = this.$UserInfoService.userInfo;
    let params: any = { pageNumber: 1, pageSize: 9999 };
    if (!isGaeaRole(role)) {
      params.org_id = org_id;
    }
    const { list } = await request({
      method: "GET",
      url: "/api/gmecamp/org/getOrganizationRelationList",
      params
    });
    for (let i = 0; i < list.length; ++i) {
      userCountMapping = { ...userCountMapping, ...await this.getOrgUserCount(list[i].org_id) };
    }
    return fromatOrganizationTreeList(list, userCountMapping);
  };

  /**
   * 辅助步骤2的方法
   * 基于循环时的机构ID
   * 获取部门以及其嫡系部门下用户的数量
   * **/
  private async getDeptUserCount(org_id: number) {
    const userCountMapping = await request({
      method: "GET",
      url: "/api/gmecamp/department/getAllDepartmentStaffCountWithOrg",
      params: { org_id }
    });
    return userCountMapping;
  };

  /**
   * 单个步骤2
   * 根据步骤1会获取到的企业列表,每个企业都要单独获取一遍部门的列表
   * **/
  private async getDepartmentList(org_id: number) {
    const userCountMapping = await this.getDeptUserCount(org_id);

    const list = await request({
      method: "GET",
      url: "/api/gmecamp/department/getDepartmentList",
      params: { org_id }
    });
    return fromatDepartmentTreeList(list, userCountMapping);
  };

  /**
   * 最终可以供外界调用的方法
   * 聚合步骤1和步骤2生成完整的树形结构
   * **/
  public async getTreeData() {
    this.treeData = null;
    /** 做一次全局组件状态更新 **/
    await this.$GlobalUIRender.executeRender();
    /** 先获取公司列表 **/
    const companyList = await this.getCompanyList();
    /** 然后递归推导子公司和部门关系 **/
    const processEveryCompany = async (inputCompanyList) => {
      return await Promise.all(inputCompanyList.map(async (everyCompany) => {
        const { org_id, children: companyChildren } = everyCompany;
        const departmentList = await this.getDepartmentList(org_id);
        const companyChildrenProcessResult = await processEveryCompany(companyChildren)
        return { ...everyCompany, children: [...departmentList, ...companyChildrenProcessResult] };
      }));
    };
    /** 把最终计算的结果保存起来 **/
    this.treeData = await processEveryCompany(companyList);
    /** 做一次全局组件状态更新 **/
    await this.$GlobalUIRender.executeRender();
  };

};