//@ts-nocheck
/* eslint-disable react/prop-types */
import { isNil } from "lodash";
import { useParams } from "react-router-dom";
import React, { useState, useCallback } from "react";
import { Form, Flex, Alert, Button, Divider, Typography, message } from "antd";

import { UploadDistDirectory } from "@/components/SuperUserComponent/UploadDistDirectory";
import { SelectModule } from "@/components/SuperUserComponent/SelectModule";
import { request } from "@/utils/request";
import propTypes from "prop-types";
import { PageContainer } from "@/components/PageContainer";

export function ModuleDeployPage(props) {

  const [form] = Form.useForm();

  const { module_id } = useParams();

  const [relaPath, setRelaPath] = useState(null);

  const handleCallback = useCallback(async () => {
    try {
      const formData = new FormData();
      const { directoryFileList, module } = await form.validateFields();
      directoryFileList.fileList.forEach((singleFile) => {
        formData.append(singleFile.originFileObj.webkitRelativePath, singleFile.originFileObj, singleFile.originFileObj.webkitRelativePath);
      });
      message.loading({ key: "handleCallback", content: "正在部署... ...", duration: 0 });
      const data = await request({
        method: "POST",
        url: "/api/gmecamp/module/publish",
        params: { module },
        data: formData
      });
      setRelaPath(data);
      message.success({ key: "handleCallback", content: "部署成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleCallback", content: error.message, duration: 3 });
    };
  }, [form]);

  return (
    <PageContainer>
      <Flex vertical gap={20}>
        <Divider>将build后的dist文件夹上传到服务器</Divider>
        <Form layout="vertical" form={form}>
          <Form.Item name="directoryFileList" label="项目的dist目录" rules={[{ required: true }]}>
            <UploadDistDirectory />
          </Form.Item>
          <Form.Item name="module" label="关联已添加的模块" initialValue={Number(module_id)} rules={[{ required: true }]}>
            <SelectModule disabled={!isNil(module_id)} />
          </Form.Item>
          <Form.Item>
            <Button block size="large" type="primary" onClick={handleCallback}>
              提交上传
            </Button>
          </Form.Item>
          <Alert
            type="success"
            message="访问manifest.json的相对路径"
            description={(
              <Typography.Paragraph copyable>
                {relaPath}
              </Typography.Paragraph>
            )}
          />
        </Form>
      </Flex>
    </PageContainer>
  )
};


ModuleDeployPage.propTypes = {
  org_id: propTypes.number
};

ModuleDeployPage.defaultProps = {

};