/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import React, { useMemo, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Flex, Table, Badge, Space, Button, Divider } from "antd";

import { DisplayUserModule } from "@/components/OrganizationUserComponent/DisplayUserModule";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";

import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";
import { DepartmentManagerService } from "@/services/framework/DepartmentManagerService";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";

import { ModifyPasswordLayer } from "@/components/ModifyPasswordLayer";
import { UpdateUserLayer } from "../../floatlayers/UpdateUserLayer";
import { DeleteUserLayer } from "../../floatlayers/DeleteUserLayer";

import { isSuper, isDev, isOper, aboveDev, aboveOper } from "@/utils/checkRole"

export function DepartmentUserListTable(props) {

  const [searchParams] = useSearchParams();
  const query_org_id = searchParams.get("org_id");
  const query_dept_id = searchParams.get("dept_id");

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  const organizationManagerService: OrganizationManagerService = useDependencieEveryTime(OrganizationManagerService);

  const departmentManagerService: DepartmentManagerService = useDependencieEveryTime(DepartmentManagerService);


  const userManagerService: UserManagerService = useDependencieEveryTime(UserManagerService);
  const { userList } = userManagerService;

  const orgDeptTreeDataService: OrgDeptTreeDataService = useDependencieEveryTime(OrgDeptTreeDataService);

  const checkRoleDisable = function (record) {
    let updateUserDisable = true;
    let resetPwdDisable = true;
    let delUserDisable = true;


    if (isSuper(record.role)) {
      updateUserDisable = resetPwdDisable = !(userInfo.user_id == record.user_id);
    } else if ((isDev(record.role))) {
      updateUserDisable = resetPwdDisable = delUserDisable = !aboveDev(userInfo.role);
    } else if (isOper(record.role)) {
      updateUserDisable = resetPwdDisable = delUserDisable = !aboveOper(userInfo.role);
    } else {
      updateUserDisable = resetPwdDisable = delUserDisable = false
    }
    return { updateUserDisable, resetPwdDisable, delUserDisable };
  }

  const columns: any[] = useMemo(() => {
    return [{
      key: "username",
      align: "center",
      width: 150,
      title: "用户名",
      dataIndex: "username",
      render(value, record) {
        if (userInfo.user_id === record.user_id) {
          return (
            <Badge.Ribbon text="我" color="green">
              <div>{value ? value : "无"}</div>
            </Badge.Ribbon>
          )
        };
        return (
          <div>{value ? value : "无"}</div>
        )
      }
    }, {
      key: "role",
      align: "center",
      width: 150,
      title: "权限等级",
      dataIndex: "role"
    }, {
      key: "modules",
      align: "center",
      title: "关联模块",
      dataIndex: "modules",
      render(modules, record) {
        return <DisplayUserModule value={modules} />
      },
    }, {
      key: "operate",
      width: 360,
      align: "center",
      title: "相关操作",
      render(currentRecord) {
        const disableMap = checkRoleDisable(currentRecord);
        let orgDisabled = organizationManagerService.organizationInfo?.disabled || departmentManagerService.departmentInfo?.relation_organization?.disabled;
        return (
          <Space split={(<Divider type="vertical" />)}>
            {orgDisabled ? null :
              <Flex gap={10} >
                <Button disabled={disableMap.updateUserDisable} type="primary" onClick={() => userManagerService.updateUserController.open(currentRecord)}>更新用户</Button>
                <Button disabled={disableMap.resetPwdDisable} type="primary" onClick={() => userManagerService.modifyPasswordController.open(currentRecord)}>修改密码</Button>
                <Button disabled={disableMap.delUserDisable} danger type="primary" onClick={() => userManagerService.deleteUserController.open(currentRecord)}>删除用户</Button>
              </Flex>}
          </Space>
        );
      }
    }];
  }, []);


  async function getUserList() {
    if (query_org_id) {
      await userManagerService.getUserListWithOrganization(Number(query_org_id));
    } else if (query_dept_id) {
      await userManagerService.getUserListWithDepartment(Number(query_dept_id));
    }
  }

  return (
    <Flex vertical gap={20} justify="space-between">
      <Button disabled={organizationManagerService.organizationInfo?.disabled || departmentManagerService.departmentInfo?.relation_organization?.disabled}
        type="primary" style={{ marginLeft: "auto" }} onClick={() => userManagerService.addUserController.open()}>新增用户</Button>
      <Table
        bordered
        rowKey="user_id"
        columns={columns}
        dataSource={userList}
      />
      <ModifyPasswordLayer
        controller={userManagerService.modifyPasswordController}
        onSubmit={(form, organizationUserInfo) => userManagerService.modifyPassword(organizationUserInfo, form)}
      />
      <UpdateUserLayer
        controller={userManagerService.updateUserController}
        onSubmit={async (userInfo, form) => {
          await userManagerService.updateUser(userInfo, form);
          await orgDeptTreeDataService.getTreeData();
          await getUserList();
        }}
      />
      <DeleteUserLayer
        controller={userManagerService.deleteUserController}
        onSubmit={async (userInfo) => {
          await userManagerService.deleteUser(userInfo);
          await orgDeptTreeDataService.getTreeData();
          await getUserList();
        }}
      />
    </Flex>
  )
};


DepartmentUserListTable.propTypes = {

};

DepartmentUserListTable.defaultProps = {
  departmentList: []
};