import { injectable, inject } from "inversify";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { UserInfoService } from "@/services/framework/UserInfoService";

import { request } from "@/utils/request";

import { isGaeaRole, aboveDev, adoveAdmin } from "@/utils/checkRole";

@injectable()
export class MenuService {

  constructor(
    @inject(SubSystemService) private readonly $SubSystemService: SubSystemService,
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService
  ) { };

  /** 根据当前的子系统获取菜单列表 **/
  public async getMenuListWithSubSystemId(inputSubSystemId?: number) {
    const userInfo = await this.$UserInfoService.getSessionInfo();
    const subSystemId = inputSubSystemId || this.$SubSystemService.getSubSystemId()
    if (this.$SubSystemService.routerIsMain(subSystemId)) {

      const subsystemMenuItem = {
        key: "subsystem",
        label: "子系统",
        icon: "AppstoreOutlined",
        children: [{
          icon: "DeploymentUnitOutlined",
          key: "/main/module",
          label: "模块管理"
        }, {
          key: "/main/subsystem",
          icon: "AppstoreAddOutlined",
          label: "子系统管理"
        }]
      };
      const microservicesMenuItem = {
        key: "microservices",
        label: "微服务",
        icon: "BookOutlined",
        children: [{
          key: "/main/services",
          icon: "BlockOutlined",
          label: "微服务套接"
        }]
      };

      const basicItems = [];
      if (adoveAdmin(userInfo.role)) {
        basicItems.push({
          key: "/main/company",
          label: "机构管理",
          icon: "ApartmentOutlined"
        });
      };
      if (isGaeaRole(userInfo.role)) {
        basicItems.push(subsystemMenuItem);
      }
      if (aboveDev(userInfo.role)) {
        basicItems.push(microservicesMenuItem);
      }
      basicItems.push({
        key: "/main/setting",
        label: "个性化配置",
        icon: "SettingOutlined"
      });
      return basicItems;
    };
    try {
      const result = await request({
        method: "GET",
        url: "/api/gemcamp/menu/getMenuData",
        params: { subsystem_id: subSystemId }
      });
      return result;
    } catch (error) {
      throw error;
    };
  };

  /** 根据当前的子系统获取到第一个模块的调转链接 **/
  public async getFirstLinkWithSubSystemId(inputSubSystemId?: number) {
    const menuListWithSubSystem = await this.getMenuListWithSubSystemId(inputSubSystemId);
    if (!menuListWithSubSystem.length) {
      return false;
    };
    const firstRoutePath = menuListWithSubSystem[0].children[0].key;
    return firstRoutePath;
  };

};