import React from "react";
import { TeamOutlined } from "@ant-design/icons";

export function fromatDepartmentTreeList(originTreeList, userCountMapping) {
  return originTreeList.map((everyDepartmentNode) => {
    const { dept_id, dept_name } = everyDepartmentNode;
    const userCount = userCountMapping[dept_id] || 0;
    const { children, ...resetyDepartmentNode } = everyDepartmentNode;
    if (children instanceof Array) {
      return { key: `dept:${dept_id}`, type: "dept", title: `${dept_name} (${userCount}人)`, icon: (<TeamOutlined />), children: fromatDepartmentTreeList(children, userCountMapping), ...resetyDepartmentNode };
    };
    return { key: `dept:${dept_id}`, type: "dept", title: `${dept_name} (${userCount}人)`, icon: (<TeamOutlined />), ...resetyDepartmentNode };
  });
};