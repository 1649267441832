/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Modal, Form, Input, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function UpdateSubSystemFloatLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);
  const [subSystemInfo, setSubSystemInfo]: any[] = useState({});

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputOrganizationInfo) => {
      setSubSystemInfo(inputOrganizationInfo);
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setSubSystemInfo({});
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setSubSystemInfo({});
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setSubSystemInfo({});
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  return (
    <Modal zIndex={9999} width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="更新子系统信息" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="子系统的名称" name="subsystem_name" rules={[{ required: true }]} initialValue={subSystemInfo.subsystem_name}>
          <Input allowClear placeholder="请输入子系统的名称" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => onSubmit(form, subSystemInfo)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


UpdateSubSystemFloatLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateSubSystemFloatLayer.defaultProps = {
  onSubmit() { }
};