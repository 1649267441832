//@ts-ignore
/* eslint-disable react/prop-types */
import { Select } from "antd";
import React, { useState, useEffect } from "react";

import { request } from "@/utils/request";

export function SelectModule(props) {

  const [moduleList, setModuleList] = useState([]);

  useEffect(() => {
    requestAnimationFrame(async () => {
      const result = await request({
        method: "GET",
        url: "/api/gmecamp/module/getModuleList"
      });
      setModuleList(result);
    });
  }, []);

  return (
    <Select {...props} placeholder="请选择模块的分组">
      {moduleList.map(({ module_id, name }) => {
        return (
          //@ts-ignore
          <Select.Option key={module_id} value={module_id}>
            {name}
          </Select.Option>
        )
      })}
    </Select>
  )
};


SelectModule.propTypes = {

};

SelectModule.defaultProps = {

};