/* eslint-disable react/prop-types */
import { v4 as uuidv4 } from "uuid";
import { Upload, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect, useCallback } from "react";

// import propTypes from "prop-types";
// import classnames from "classnames";

// import css from "./style.scss";
// import css from "./style.less";

export function getUploadInitialValue(logo) {
  if (logo) {
    return [{ uid: uuidv4(), name: logo.originalname, status: "done", thumbUrl: logo.url }];
  };
  return undefined;
};

export function UploadImage(props) {

  const { value, onChange } = props;

  const fileObjectCache = useRef([]);

  const [fileList, setFileList] = useState(value);

  /** 
   * URL.revokeObjectURL
   * @description 组件移除的时候需要释放BLOB URL
   * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL_static
   * **/
  useEffect(() => {
    return () => {
      fileObjectCache.current.forEach((blobURL) => URL.revokeObjectURL(blobURL));
    };
  }, []);

  const handleBeforeUpload = useCallback((info) => {
    const blobURL = URL.createObjectURL(info);
    fileObjectCache.current.push(blobURL);
    const singleFileInfo = {
      uid: uuidv4(),
      name: info.name,
      status: "done",
      thumbUrl: blobURL,
      file: info
    };
    const composeFileList = [...fileList, singleFileInfo];
    setFileList(composeFileList);
    onChange(composeFileList);
    return false;
  }, [fileList]);

  const handleRemove = useCallback((info) => {
    URL.revokeObjectURL(info.thumbUrl);
    const afterFileList = fileList.filter((fileInfo) => fileInfo.uid !== info.uid);
    setFileList(afterFileList);
    onChange(afterFileList);
  }, [fileList]);

  if (fileList.length) {
    return (
      <Upload
        listType="picture"
        fileList={fileList}
        accept=".jpg,.jpeg,.png"
        beforeUpload={handleBeforeUpload}
        onRemove={handleRemove}
      />
    )
  };

  return (
    <Upload.Dragger
      listType="picture"
      fileList={fileList}
      accept=".jpg,.jpeg,.png"
      beforeUpload={handleBeforeUpload}
      onRemove={handleRemove}
    >
      <p className="ant-upload-drag-icon">
        {/** @ts-ignore **/}
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading organization data or other
        banned files.
      </p>
    </Upload.Dragger>
  )

};


UploadImage.propTypes = {

};

UploadImage.defaultProps = {
  value: [],
  onChange() { }
};