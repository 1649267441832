/* eslint-disable react/prop-types */
import { Select } from "antd";
import propTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { request } from "@/utils/request";

export function SelectGroup(props) {
  const { subsystem_id, value, onChange } = props;
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    requestAnimationFrame(async () => {
      if (!subsystem_id) {
        onChange(null);
        return false;
      };
      const result = await request({
        method: "GET",
        url: "/api/gemcamp/group/getGroupList",
        params: { subsystem_id }
      });
      const valueResult = result.map(({ module_group_id }) => {
        return module_group_id;
      });
      if (!valueResult.includes(value)) {
        onChange(null);
      };
      setGroupList([{ module_group_id: null, group_name: "未指定" }, ...result]);
    });
  }, [subsystem_id]);

  return (
    <Select {...props} placeholder="请选择分组">
      {groupList.map(({ module_group_id, group_name }) => {
        return (
          //@ts-ignore
          <Select.Option key={module_group_id} value={module_group_id}>
            {group_name}
          </Select.Option>
        )
      })}
    </Select>
  )
};


SelectGroup.propTypes = {
  disabled: propTypes.bool,
  subsystem_id: propTypes.number
};

SelectGroup.defaultProps = {

};