/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { MenuService } from "@/services/framework/MenuService";

import { RedirectWithRole } from "@/services/router/RedirectWithRole";

export function SubSystemRedirectPage(props) {
  const navigate = useNavigate();
  const { sub_system_id } = useParams();

  const redirectWithRole: RedirectWithRole = useDependencieEveryTime(RedirectWithRole);

  const subSystemService: SubSystemService = useDependencieEveryTime(SubSystemService);

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userAssignModules } = userInfoService;

  const menuService: MenuService = useDependencieEveryTime(MenuService);

  useEffect(() => {
    requestAnimationFrame(async () => {
      if (subSystemService.routerIsLogin(sub_system_id)) {
        // await userInfoService.doLogout();
        return false;
      };
      if (subSystemService.routerIsMain(sub_system_id)) {
        navigate(redirectWithRole.getDefaultPageLinkHref());
        return false;
      };
      if (!userAssignModules.length) {
        navigate(redirectWithRole.getDefaultPageLinkHref());
        return false;
      };
      const linkHref = await menuService.getFirstLinkWithSubSystemId();
      if (linkHref) {
        navigate(linkHref);
        return false;
      };
    });
  }, [userAssignModules, sub_system_id]);

  if (!sub_system_id) {
    return (
      <div>不存在的子系统</div>
    )
  };
  return null;
};


SubSystemRedirectPage.propTypes = {

};

SubSystemRedirectPage.defaultProps = {

};