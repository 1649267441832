/* eslint-disable react/prop-types */
import { Form, Modal, Input, Button, Divider, DatePicker } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { UploadImage } from "@/components/SuperUserComponent/UploadImage";
import propTypes from "prop-types";
import { SelectParentOrganization } from "@/components/SuperUserComponent/SelectParentOrganization";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";

import { usernameValidator } from "@/utils/usernameValidator";
import { confirmPasswordRule, validatorPassword } from "@/utils/confirmPasswordRule";

import { FloatController } from "@/utils/FloatController";

export function AddOrganizationLayer(props) {
  const { controller, onSubmit, thisOrg_id } = props;

  const [status, setStatus] = useState(false);

  const userInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => { setStatus(true) });
    controller.registryConfirmHandler(() => { setStatus(false) });
    controller.registryCancelHandler(() => { setStatus(false) });
    controller.registryCloseHandler(() => { setStatus(false) });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  const handleSubmit = useCallback(async (form) => {
    await form.validateFields();
    await onSubmit(form);
    await controller.close();
  }, [controller]);

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="新增组织" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item shouldUpdate>
          {(form) => {
            return (
              <React.Fragment>
                <Form.Item label="组织的LOGO" name="logo_id">
                  <UploadImage />
                </Form.Item>
                <Form.Item label="上级单位" name="parent_org_id" rules={[{ required: true }]} initialValue={thisOrg_id || undefined}>
                  <SelectParentOrganization />
                </Form.Item>
                <Form.Item label="组织的名称" name="org_name" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入组织名称" />
                </Form.Item>
                <Form.Item label="运行容量(单位:kVA)" name="capacity" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入运行容量" />
                </Form.Item>
                <Form.Item label="联系人" name="contact" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入联系人" />
                </Form.Item>
                <Form.Item label="联系人手机" name="phone" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入联系人手机" />
                </Form.Item>
                <Form.Item label="电子邮箱" name="email" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入电子邮箱" />
                </Form.Item>
                <Form.Item label="有效期" name="expire" rules={[{ required: true }]}>
                  <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  <Button block type="primary" onClick={() => handleSubmit(form)}>确认提交</Button>
                </Form.Item>
              </React.Fragment>
            )
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AddOrganizationLayer.propTypes = {
  thisOrg_id: propTypes.number,
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

AddOrganizationLayer.defaultProps = {
  onSubmit() { }
};