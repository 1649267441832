/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { confirmPasswordRule, validatorPassword } from "@/utils/confirmPasswordRule";
import { FloatController } from "@/utils/FloatController";
import { isSuper } from "@/utils/checkRole";

export function ModifyPasswordLayer(props) {
    const { controller, onSubmit } = props;

    const [status, setStatus] = useState(false);
    const [organizationUserInfo, setOrganizationUserInfo] = useState({});

    /** 注册弹窗句柄 **/
    useEffect(() => {
        controller.registryOpenHandler((inputModuleInfo) => {
            setOrganizationUserInfo(inputModuleInfo);
            setStatus(true);
        });
        controller.registryConfirmHandler(() => {
            setOrganizationUserInfo({});
            setStatus(false);
        });
        controller.registryCancelHandler(() => {
            setOrganizationUserInfo({});
            setStatus(false);
        });
        controller.registryCloseHandler(() => {
            setOrganizationUserInfo({});
            setStatus(false);
        });
        return () => { controller.clearEffect() }
    }, [controller]);

    const handleCancel = useCallback(async () => {
        await controller.close();
    }, [controller]);

    const hiddenFlag = function (): boolean {
        if (isSuper(organizationUserInfo['role'])) {
            return false;
        }
        return true;
    }

    return (
        <Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="修改密码" onCancel={handleCancel}>
            <Form layout="vertical">
                <Form.Item shouldUpdate>
                    {(form) => {
                        return (
                            <React.Fragment>
                                <Form.Item hidden={hiddenFlag()} label="原始密码" name="oldPassword" >
                                    <Input.Password allowClear placeholder="请输入原始密码" />
                                </Form.Item>
                                <Form.Item label="新密码" name="password" rules={[{ required: true }, { validator: validatorPassword() }]}>
                                    <Input.Password allowClear placeholder="请输入新密码" />
                                </Form.Item>
                                <Form.Item label="确认输入" name="confirm" rules={[{ required: true }, { validator: confirmPasswordRule(form) }]}>
                                    <Input.Password allowClear placeholder="请确认输入" />
                                </Form.Item>
                                <Form.Item>
                                    <Button block type="primary" onClick={() => onSubmit(form, organizationUserInfo)}>确认提交</Button>
                                </Form.Item>
                            </React.Fragment>
                        )
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
};


ModifyPasswordLayer.propTypes = {
    controller: propTypes.instanceOf(FloatController),
    onSubmit: propTypes.func
};

ModifyPasswordLayer.defaultProps = {
    onSubmit() { }
};
