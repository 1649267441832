/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { LoginPageEntry } from "@/commons/LoginPageEntry";
import { OrganizationUserPageEntry } from "@/commons/OrganizationUserPageEntry";

import { UserInfoService } from "@/services/framework/UserInfoService";

export function CampApplication(props) {

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  useEffect(() => {
    requestAnimationFrame(async () => {
      try {
        /** 先获取一下用户的登陆信息 **/
        const fetchSessionInfo = await userInfoService.getSessionInfo();
        /** 不存在登陆信息的话就要去到登陆页面 **/
        if (!fetchSessionInfo) {
          window.location.pathname = "/login";
        } else {
          if (window.location.pathname === "/login") {
            window.location.pathname = "/initialize";
          };
        }

      } catch (error) {
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        };
      };
    });
  }, [userInfoService]);

  /** 渲染登陆页的情况 **/
  if (!userInfo && window.location.pathname === "/login") {
    return (
      <LoginPageEntry />
    )
  } else if (userInfo) {
    return (
      <OrganizationUserPageEntry />
    )
  } else {
    return null;
  }


};


CampApplication.propTypes = {

};

CampApplication.defaultProps = {

};