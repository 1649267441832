/* eslint-disable react/prop-types */
import { Flex, Button } from "antd";
import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { PageContainer } from "@/components/PageContainer";
import { FloatController } from "@/utils/FloatController";
import { AddOrganizationLayer } from "./OrganizationManagerPage/floatlayers/AddOrganizationLayer";

import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";
import { DepartmentManagerService } from "@/services/framework/DepartmentManagerService";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { ModuleService } from "@/services/framework/ModuleService";

import { DepartmentUserListTable } from "./components/DepartmentUserListTable";
import { OrgDeptTreeData } from "./components/OrgDeptTreeData";

import { OrgDeptDespLayer } from "./floatlayers/OrgDeptDespLayer";




export function CompanyManagerPage(props) {

  const [searchParams] = useSearchParams();
  const query_org_id = Number(searchParams.get("org_id"));
  const query_dept_id = Number(searchParams.get("dept_id")) || -1;

  const organizationManagerService: OrganizationManagerService = useDependencieEveryTime(OrganizationManagerService);
  const departmentManagerService: DepartmentManagerService = useDependencieEveryTime(DepartmentManagerService);
  const orgDeptTreeDataService: OrgDeptTreeDataService = useDependencieEveryTime(OrgDeptTreeDataService);
  const userManagerService: UserManagerService = useDependencieEveryTime(UserManagerService);
  const moduleService: ModuleService = useDependencieEveryTime(ModuleService);

  useEffect(() => {
    const cancelIndex = requestAnimationFrame(async () => {
      /** 加载树形结构 **/
      await orgDeptTreeDataService.getTreeData();
    });
  }, [orgDeptTreeDataService]);

  useEffect(() => {
    const cancelIndex = requestAnimationFrame(async () => {
      /** 如果查询字符串的内容是机构id的话就查看当前机构相关的内容 **/
      if (query_org_id) {
        await organizationManagerService.getOrganizationInfo(query_org_id);
        await moduleService.getAssignModuleWithOrganization(query_org_id);
        await userManagerService.getUserListWithOrganization(query_org_id);
        cancelAnimationFrame(cancelIndex);
        return false;
      };
      /** 如果查询字符串的内容是部门id的话就查看当前部门相关的内容 **/
      if (query_dept_id && query_dept_id !== -1) {
        await departmentManagerService.getDepartmentInfo(query_dept_id);
        await moduleService.getAssignModuleWithOrganization(departmentManagerService.departmentInfo?.relation_organization?.org_id);
        await userManagerService.getUserListWithDepartment(query_dept_id);
        cancelAnimationFrame(cancelIndex);
        return false;
      };
    });
  }, [query_org_id, query_dept_id, userManagerService]);


  const addOrganizationController = useMemo(() => {
    return new FloatController();
  }, []);



  return (
    <PageContainer>
      <Flex gap={20} style={{ width: "100%", height: "100%" }}>
        <Flex vertical style={{ width: 300, background: "white" }}>
          <Button type="primary" icon={(<PlusOutlined />)} onClick={() => addOrganizationController.open()}>
            新增组织
          </Button>
          <OrgDeptTreeData initSelNode={orgDeptTreeDataService?.treeData?.length ? orgDeptTreeDataService.treeData[0] : null} />
        </Flex>
        <Flex gap={20} vertical flex={1}>
          <OrgDeptDespLayer />
          <DepartmentUserListTable />
        </Flex>

        {/* 下面都是跟弹窗相关的组件 */}
        <AddOrganizationLayer
          thisOrg_id={query_org_id}
          controller={addOrganizationController}
          onSubmit={async (form) => {
            await organizationManagerService.addOrganization(form);
            await orgDeptTreeDataService.getTreeData();
          }}
        />

      </Flex >
    </PageContainer>
  )
};


CompanyManagerPage.propTypes = {

};

CompanyManagerPage.defaultProps = {

};