/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Form, Modal, Input, Button, Divider } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function AddProxyRuleLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => { setStatus(true) });
    controller.registryConfirmHandler(() => { setStatus(false) });
    controller.registryCancelHandler(() => { setStatus(false) });
    controller.registryCloseHandler(() => { setStatus(false) });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="添加规则" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item shouldUpdate>
          {(form) => {
            return (
              <React.Fragment>
                <Form.Item label="命中的规则" name="pattern" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入命中的规则" />
                </Form.Item>
                <Form.Item label="目标服务器" name="target_url" rules={[{ required: true }]}>
                  <Input allowClear placeholder="请输入目标服务器" />
                </Form.Item>
                <Form.Item label="规则的简易描述" name="description" rules={[{ required: true }]}>
                  <Input.TextArea allowClear placeholder="请输入规则的简易描述" />
                </Form.Item>
                <Form.Item shouldUpdate>
                  <Button block type="primary" onClick={() => onSubmit(form)}>确认提交</Button>
                </Form.Item>
              </React.Fragment>
            )
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AddProxyRuleLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

AddProxyRuleLayer.defaultProps = {
  onSubmit() { }
};