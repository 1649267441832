/* eslint-disable react/prop-types */
import { LoadingOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { SubSystemService } from "@/services/framework/SubSystemService";

import { loadRemoteComponent } from "@/utils/loadRemoteComponent";

export function ResourceComponent({ remoteResource }) {

  const userInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo, userAssignModules } = userInfoService;

  const subSystemService = useDependencieEveryTime(SubSystemService);
  const subSystemId = subSystemService.getSubSystemId();

  const [loadError, setLoadError] = useState(null);
  const [InputResourceComponent, setInputResourceComponent] = useState(null);

  useEffect(() => {
    requestAnimationFrame(async () => {
      try {
        setInputResourceComponent(null);
        await new Promise((resolve) => setTimeout(resolve, 100));
        const LazyComponent = await loadRemoteComponent(remoteResource);
        setInputResourceComponent(LazyComponent);
      } catch (error) {
        setLoadError(error);
      };
    });
    return () => {
      setLoadError(null);
    };
  }, [remoteResource]);

  if (loadError) {
    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <div style={{ textAlign: "center", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>
          <span>加载失败,</span>
          <a onClick={() => window.location.reload()}>请点击该按钮重新加载</a>
        </div>
      </div>
    );
  };

  if (!InputResourceComponent) {
    return null;
  };

  return (
    <React.Suspense
      fallback={(
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <div style={{ color: "lightgray", textAlign: "center", fontSize: 200, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }} >
            <LoadingOutlined />
          </div>
        </div>
      )}
    >
      <InputResourceComponent runtimePublicPath={remoteResource} subsystemId={subSystemId} userInfo={userInfo} userAssignModules={userAssignModules} theme={userInfo.theme || "#1677ff"} />
    </React.Suspense>
  )
};


ResourceComponent.propTypes = {

};

ResourceComponent.defaultProps = {

};