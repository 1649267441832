import axios from "axios";
import { isIP } from "is-ip";

export async function request(params) {
  const levelOneDomain = isIP(window.location.hostname) ? window.location.hostname : window.location.hostname.split(".").slice(-2).join(".");
  const { data }: any = await axios({
    ...params,
    headers: {
      levelOneDomain
    }
  });
  if (data.code === 0) {
    return data.data;
  } else {

    //登录页里面有鉴权操作，产生循环调用
    if (data.code === 401) {
      // window.location.pathname = "/login";
    }

    throw new Error(data.message);
  }
};