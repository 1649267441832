/* eslint-disable react/prop-types */
import { TreeSelect } from "antd";
import propTypes from "prop-types";
import { traverse } from "object-traversal";
import React, { useState, useEffect } from "react";

import { request } from "@/utils/request";

export function SelectDepartment(props) {

  const { org_id, exclude, childrenExclude } = props;

  const [departmentList, setDepartmentList] = useState(null);

  useEffect(() => {
    const cancelToken = requestAnimationFrame(async () => {
      const result = await request({
        method: "GET",
        url: "/api/gmecamp/department/getDepartmentList",
        params: { org_id }
      });
      const cloneList = JSON.parse(JSON.stringify(result));
      traverse(cloneList, ({ parent, key, value }) => {
        if (key === "dept_id") {
          parent.value = value;
          if (value === exclude) {
            parent.disabled = true;
            if (childrenExclude) {
              parent.children = null;   // 父disabled 不展示子
            }
          };
        };
        if (key === "dept_name") {
          parent.title = value;
        };
      });
      const comboList = [{ title: "未关联", value: -1 }].concat(cloneList);
      setDepartmentList(comboList);
    });
    return () => {
      cancelAnimationFrame(cancelToken);
    };
  }, [org_id]);

  if (!departmentList) {
    return null;
  };

  return (
    <TreeSelect treeDefaultExpandAll treeData={departmentList} {...props} placeholder="请选择上一级的部门" allowClear />
  )
};


SelectDepartment.propTypes = {
  org_id: propTypes.number,
  value: propTypes.number,
  onChange: propTypes.func,
  disabled: propTypes.bool,
  childrenExclude: propTypes.bool

};

SelectDepartment.defaultProps = {
  exclude: null,
  childrenExclude: false
};