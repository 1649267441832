// @ts-nocheck
import { injectable, Container } from "inversify";
import React, { useMemo, createContext, useContext } from "react";

export const Context = createContext({ IOCContainer: null });

/**
 * 使用hook注入依赖项
 * 每次都从IOC容器中获取
 * 当服务的作用域为TransientScope的时候就需要用到
 * 每次组件渲染的时候都会从Context的IOC容器中进行获取服务
 * **/
export function useDependencieEveryTime(dependencie) {
  const { IOCContainer } = useContext(Context);
  return IOCContainer.get(dependencie);
};

/**
 * 使用hook注入依赖项
 * 用于细粒化控制service生命周期的hook,
 * 比如某些依赖的作用域生命周期是 RequestScope 就需要使用这个hooks
 * 只在组件初始话的时候从IOC容器中获取一次
 * **/
export function useDependencieOneTime(dependencie) {
  const { IOCContainer } = useContext(Context);
  const getDependencie = useMemo(() => {
    return IOCContainer.get(dependencie);
  }, []);
  return getDependencie;
};


/**
 * 用于后期在类中进行注入,搭配其他的Service进行UI更新
 * **/
@injectable()
export class GlobalUIRender {

  public executeRender: any;

  public registryRenderHandler(renderCallback) {
    if (this.executeRender instanceof Function) {
      console.warn("renderCallback已存在,请勿重复声明");
      return false;
    };
    this.executeRender = renderCallback;
  };

};

interface IProps {
  children: any,
  IOCContainer: Container
};

interface IState {
  isReady: boolean
};

/**
 * 为后续的React子节点提供IOC控制容器的上下文
 * **/
export class IOCContextProvider extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = { isReady: false };
  };

  componentDidMount(): void {
    /** 在组件刚刚挂载的时候要把GlobalUIRender一起挂载上去 **/
    const { IOCContainer } = this.props;
    IOCContainer.bind(GlobalUIRender).toSelf().inSingletonScope();
    IOCContainer.get(GlobalUIRender).registryRenderHandler(() => this.forceUpdate());
    this.setState({ isReady: true });
  };

  render() {
    const { isReady } = this.state;
    if (!isReady) {
      return null;
    };
    const { children, IOCContainer } = this.props;
    return (
      <Context.Provider value={{ IOCContainer }}>
        {children}
      </Context.Provider>
    )
  };

};