/* eslint-disable react/prop-types */
import React from "react";
import { Flex } from "antd";

import { BannerBlock } from "./components/BannerBlock";
import { LoginFormBlock } from "./components/LoginFormBlock";

export function LoginPage(props) {
  return (
    <Flex style={{ height: "100%" }} align="center">
      <Flex style={{ width: "60%", height: "100%" }}>
        <BannerBlock />
      </Flex>
      <Flex style={{ width: "40%", height: "100%" }} align="center">
        <LoginFormBlock />
      </Flex>
    </Flex>
  )
};


LoginPage.propTypes = {

};

LoginPage.defaultProps = {

};