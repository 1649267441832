/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import React, { useMemo, useCallback } from "react";
import { Flex, Space, Button, Divider, Descriptions, message } from "antd";

import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";

import { UpdateSubSystemFloatLayer } from "../../floatlayers/UpdateSubSystemFloatLayer";
import { DeleteSubSystemFloatLayer } from "../../floatlayers/DeleteSubSystemFloatLayer";

export function SubSystyemInfomation(props) {

  const { subsystemInfomation, onReloadSubSystemList } = props;

  /** 更新分类 **/
  const updateSubSystemController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleUpdateSubSystem = useCallback(async (form, subsystemInfo) => {
    try {
      const result = await form.validateFields();
      message.loading({ key: "handleUpdateSubSystem", content: "正在更新子系统... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/subsystem/updateSubSystem",
        data: { subsystem_id: subsystemInfo.subsystem_id, ...result }
      });
      await onReloadSubSystemList();
      message.success({ key: "handleUpdateSubSystem", content: "子系统更新成功!", duration: 3 });
      await updateSubSystemController.close();
    } catch (error) {
      message.error({ key: "handleUpdateSubSystem", content: error.message, duration: 3 });
    };
  }, [updateSubSystemController, onReloadSubSystemList]);

  /** 删除分类 **/
  const deleteSubSystemController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleDeleteSubSystem = useCallback(async (record) => {
    try {
      message.loading({ key: "handleDeleteSubSystem", content: "正在删除子系统... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/subsystem/deleteSubSystem",
        data: { subsystem_id: record.subsystem_id }
      });
      await onReloadSubSystemList();
      message.success({ key: "handleDeleteSubSystem", content: "子系统删除成功!", duration: 3 });
      await deleteSubSystemController.close();
    } catch (error) {
      message.error({ key: "handleDeleteSubSystem", content: error.message, duration: 3 });
    };
  }, [deleteSubSystemController, onReloadSubSystemList]);

  if (!subsystemInfomation) {
    return null;
  };

  return (
    <Flex vertical flex={1}>
      <Descriptions
        bordered
        title="子系统详情"
        layout="vertical"
        extra={(
          <Space split={(<Divider type="vertical" />)}>
            <Button type="primary" onClick={() => updateSubSystemController.open(subsystemInfomation)}>编辑子系统信息</Button>
            <Button type="primary" danger onClick={() => deleteSubSystemController.open(subsystemInfomation)}>删除子系统</Button>
          </Space>
        )}
      >
        <Descriptions.Item label="子系统ID">{subsystemInfomation.subsystem_id}</Descriptions.Item>
        <Descriptions.Item label="子系统名称">{subsystemInfomation.subsystem_name}</Descriptions.Item>
      </Descriptions>
      <UpdateSubSystemFloatLayer
        controller={updateSubSystemController}
        onSubmit={handleUpdateSubSystem}
      />
      <DeleteSubSystemFloatLayer
        controller={deleteSubSystemController}
        onSubmit={handleDeleteSubSystem}
      />
    </Flex>
  )
};


SubSystyemInfomation.propTypes = {
  subsystemInfomation: propTypes.object
};

SubSystyemInfomation.defaultProps = {
  onReloadSubSystemList() { }
};