/* eslint-disable react/prop-types */
import { Modal, Form, Input, Button } from "antd";
import propTypes from "prop-types";
import React, { useRef, useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function DelOrganizationLayer(props) {
	const { controller, onSubmit } = props;

	const [organizationInfo, setOrganizationInfo]: any[] = useState({});
	const [status, setStatus] = useState(false);

	/** 注册弹窗句柄 **/
	useEffect(() => {
		controller.registryOpenHandler((currentRecord) => {
			setOrganizationInfo(currentRecord);
			setStatus(true);
		});
		controller.registryConfirmHandler(() => {
			setOrganizationInfo({});
			setStatus(false);
		});
		controller.registryCancelHandler(() => {
			setOrganizationInfo({});
			setStatus(false);
		});
		controller.registryCloseHandler(() => {
			setOrganizationInfo({});
			setStatus(false);
		});
		return () => { controller.clearEffect() }
	}, [controller]);

	const handleCancel = useCallback(async () => {
		await controller.close();
	}, [controller]);

	const handleSubmit = useCallback(async (form, orgId) => {
		await onSubmit(form, orgId);
		await controller.close();
	}, [controller]);

	return (
		<Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title={`删除组织: ${organizationInfo?.org_name}`} onCancel={handleCancel}>
			<Form layout="vertical">
				<Form.Item shouldUpdate>
					{(form) => {
						return (
							<React.Fragment>
								<Form.Item label="登录密码" name="password" rules={[{ required: true }]}>
									<Input.Password allowClear placeholder="请输入密码" />
								</Form.Item>
								<Form.Item>
									<Button block danger type="primary" onClick={async () => await handleSubmit(form, organizationInfo.org_id)}>确认提交</Button>
								</Form.Item>
							</React.Fragment>
						)
					}}
				</Form.Item>
			</Form>
		</Modal>
	)
};


DelOrganizationLayer.propTypes = {
	controller: propTypes.instanceOf(FloatController),
	onSubmit: propTypes.func
};

DelOrganizationLayer.defaultProps = {
	onSubmit() { }
};