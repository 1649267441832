/* eslint-disable react/prop-types */
import { Select } from "antd";
import propTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { request } from "@/utils/request";

export function SelectOrganizationUser(props) {

  const { org_id, ...otherProps } = props;

  const [organizationUserList, setOrganizationUserList] = useState([]);

  useEffect(() => {
    requestAnimationFrame(async () => {
      const { list } = await request({
        method: "GET",
        url: "/api/gmecamp/org/getOrganizationUserList",
        params: { org_id, pageNumber: 1, pageSize: 9999, userType: 'user' }
      });
      setOrganizationUserList(list);
    });
  }, [org_id]);

  return (
    <Select {...otherProps} mode="multiple" placeholder="请选择要关联的用户">
      {organizationUserList.map(({ user_id, username }) => {
        return (
          <Select.Option key={Number(user_id)} value={Number(user_id)}>
            {username}
          </Select.Option>
        )
      })}
    </Select>
  )
};


SelectOrganizationUser.propTypes = {
  org_id: propTypes.number,
  value: propTypes.instanceOf(Array),
  onChange: propTypes.func
};

SelectOrganizationUser.defaultProps = {

};