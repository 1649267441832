/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button, Flex } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import { SelectSubSystem } from "@/components/SuperUserComponent/SelectSubSystem";
import { SelectGroup } from "@/components/SuperUserComponent/SelectGroup";

import { FloatController } from "@/utils/FloatController";

import { IconSel } from "@/components/IconSel";

export function UpdateModuleLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);
  const [moduleInfo, setModuleInfo]: any[] = useState({});

  const [form] = Form.useForm();
  const iconSelController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputModuleInfo) => {
      setModuleInfo(inputModuleInfo);
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setModuleInfo({});
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setModuleInfo({});
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setModuleInfo({});
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  const saveIcon = function (xxx, iconName) {
    form.setFieldsValue({
      iconName
    });
    iconSelController.close();
  }

  useEffect(() => {
    form.setFieldsValue({
      name: moduleInfo.name,
      rule: moduleInfo.rule,
      href: moduleInfo.href,
      resource: moduleInfo.resource,
      iconName: moduleInfo.iconName,
      subsystem_id: moduleInfo?.relation_subsystem?.subsystem_id,
      module_group_id: moduleInfo?.relation_group?.module_group_id,
    })
  }, [moduleInfo]);

  return (
    <Flex gap={20} vertical flex={1}>
      <Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="修改模块" onCancel={handleCancel}>
        <Form layout="vertical" form={form}>
          <Form.Item label="模块名称" name="name" rules={[{ required: false }]} >
            <Input allowClear placeholder="请输入模块名称" />
          </Form.Item>
          <Form.Item label="所属子系统" name="subsystem_id" rules={[{ required: false }]} tooltip="如果不关联子系统则无法进行分配,只是简单声明了一个模块">
            <SelectSubSystem />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              return (
                <Form.Item label="所属分组" name="module_group_id" rules={[{ required: false }]} tooltip="这个代表企业端侧边栏菜单的分组">
                  <SelectGroup subsystem_id={form.getFieldValue("subsystem_id")} />
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label="路由匹配规则" name="rule" rules={[{ required: false }]} >
            <Input allowClear addonBefore={get(moduleInfo, "relation_subsystem.subsystem_id", "{{子系统ID}}")} placeholder="请输入路由的匹配规则" />
          </Form.Item>
          <Form.Item label="调转链接" name="href" rules={[{ required: false }]} >
            <Input allowClear addonBefore={get(moduleInfo, "relation_subsystem.subsystem_id", "{{子系统ID}}")} placeholder="请输入调转链接" />
          </Form.Item>
          <Form.Item label="mainfast.json资源链接" name="resource" rules={[{ required: false }]} >
            <Input allowClear placeholder="请输入mainfast.json资源链接" />
          </Form.Item>
          <Form.Item label="图标" name="iconName" rules={[{ required: false }]} >
            <Input allowClear placeholder="图标" />
          </Form.Item>
          <Form.Item>
            <Button type='primary' onClick={() => iconSelController.open()}>选择图标</Button>
          </Form.Item>
          <Form.Item shouldUpdate>
            {(form) => {
              return <Button block type="primary" onClick={() => onSubmit(form, moduleInfo)}>确认提交</Button>
            }}
          </Form.Item>
        </Form>
      </Modal>
      <IconSel controller={iconSelController} onOk={saveIcon}></IconSel>
    </Flex >
  )
};


UpdateModuleLayer.propTypes = {
  subsystem_id: propTypes.number,
  module_group_id: propTypes.number,
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateModuleLayer.defaultProps = {
  onSubmit() { }
};