/* eslint-disable react/prop-types */
import { Modal } from "antd";
import propTypes from "prop-types";
import React, { useRef, useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function FreezeProxyRuleLayer(props) {
  const { controller, onSubmit } = props;

  const currentRecordRef = useRef(null);
  const [status, setStatus] = useState(false);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((currentRecord) => {
      currentRecordRef.current = currentRecord;
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      currentRecordRef.current = null;
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      currentRecordRef.current = null;
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      currentRecordRef.current = null;
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  return (
    <Modal
      width={1200}
      open={status}
      title="冻结规则"
      keyboard={false}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={() => onSubmit(currentRecordRef.current)}
    >
      确定要冻结当前规则吗?
    </Modal>
  )
};


FreezeProxyRuleLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

FreezeProxyRuleLayer.defaultProps = {
  onSubmit() { }
};