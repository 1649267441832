/* eslint-disable react/prop-types */
import React from "react";
import { Checkbox } from "antd";
import propTypes from "prop-types";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { ModuleService } from "@/services/framework/ModuleService";

/**
 * 该组件的作用是从分配给组织的全量模块中再选择出一部分模块
 * 分配给其余用户
 * **/
export function DisplayUserModule(props) {
  const moduleService: ModuleService = useDependencieEveryTime(ModuleService);
  const { orgModules } = moduleService;
  return (
    <Checkbox.Group {...props} disabled placeholder="请选择为用户开放的模块">
      {(orgModules || []).map(({ module_id, name }) => {
        return (
          <Checkbox key={module_id} value={module_id}>
            {name}
          </Checkbox>)
      })}
    </Checkbox.Group>
  )
};


DisplayUserModule.propTypes = {
  value: propTypes.instanceOf(Array),
  onChange: propTypes.func
};

DisplayUserModule.defaultProps = {

};