/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { SelectOrganizationUser } from "@/components/OrganizationUserComponent/SelectOrganizationUser";
import { FloatController } from "@/utils/FloatController";

export function AssignUserLayer(props) {
  const { departmentInfo, controller, onSubmit } = props;

  const [status, setStatus] = useState(false);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => {
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  if (!departmentInfo) {
    return null;
  };

  const org_id = Number(get(departmentInfo, "relation_organization.org_id"));

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="关联用户" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="关联用户" name="users" rules={[{ required: true }]} initialValue={departmentInfo.users}>
          <SelectOrganizationUser org_id={org_id} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => onSubmit(departmentInfo, form)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AssignUserLayer.propTypes = {
  departmentInfo: propTypes.instanceOf(Object),
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

AssignUserLayer.defaultProps = {
  onSubmit() { }
};