/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Form, Modal, Input, Button, Flex, Row, Col } from "antd";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import { SelectSubSystem } from "@/components/SuperUserComponent/SelectSubSystem";
import { SelectGroup } from "@/components/SuperUserComponent/SelectGroup";

import { IconSel } from "@/components/IconSel";

import { FloatController } from "@/utils/FloatController";

export function AddModuleLayer(props) {
  const { subsystem_id, module_group_id, controller, onSubmit } = props;

  const [status, setStatus] = useState(false);

  const [form] = Form.useForm();
  const iconSelController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => { setStatus(true) });
    controller.registryConfirmHandler(() => { setStatus(false) });
    controller.registryCancelHandler(() => { setStatus(false) });
    controller.registryCloseHandler(() => { setStatus(false) });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);


  const saveIcon = function (xxx, iconName) {
    form.setFieldsValue({
      iconName
    });
    iconSelController.close();
  }

  return (
    <Flex gap={20} vertical flex={1}>
      <Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="新增模块" onCancel={handleCancel}>
        <Form layout="vertical" form={form}>
          <Form.Item label="模块名称" name="name" rules={[{ required: true }]}>
            <Input allowClear placeholder="请输入模块名称" />
          </Form.Item>
          <Form.Item label="所属子系统" name="subsystem_id" rules={[{ required: false }]} initialValue={subsystem_id} tooltip="如果不关联子系统则无法进行分配,只是简单声明了一个模块">
            <SelectSubSystem disabled />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {(form) => {
              return (
                <Form.Item label="所属分组" name="module_group_id" rules={[{ required: false }]} initialValue={module_group_id} tooltip="这个代表企业端侧边栏菜单的分组">
                  <SelectGroup disabled subsystem_id={form.getFieldValue("subsystem_id")} />
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label="路由匹配规则" name="rule" rules={[{ required: true }]}>
            <Input allowClear addonBefore={"{{子系统ID}}"} placeholder="请输入路由的匹配规则" />
          </Form.Item>
          <Form.Item label="调转链接" name="href" rules={[{ required: true }]}>
            <Input allowClear addonBefore={"{{子系统ID}}"} placeholder="请输入调转链接" />
          </Form.Item>
          <Form.Item label="mainfast.json资源链接" name="resource" rules={[{ required: false }]}>
            <Input allowClear placeholder="请输入mainfast.json资源链接" />
          </Form.Item>
          <Form.Item label="图标" name="iconName" rules={[{ required: false }]} >
            <Input allowClear placeholder="图标" />
          </Form.Item>
          <Form.Item>
            <Button type='primary' onClick={() => iconSelController.open()}>选择图标</Button>
          </Form.Item>
          <Form.Item shouldUpdate>
            {(form) => {
              return <Button block type="primary" onClick={() => onSubmit(form)}>确认提交</Button>
            }}
          </Form.Item>
        </Form>
      </Modal>
      <IconSel controller={iconSelController} onOk={saveIcon}></IconSel>
    </Flex>
  )
};


AddModuleLayer.propTypes = {
  subsystem_id: propTypes.number,
  module_group_id: propTypes.number,
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

AddModuleLayer.defaultProps = {
  onSubmit() { }
};