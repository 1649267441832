

export function groupWithCategory(withModuleOrganizationUserModuleList) {
  const categoryCollection = {};
  withModuleOrganizationUserModuleList.forEach(({ relation_subsystem }) => {
    if (relation_subsystem) {
      const compose_category = { key: relation_subsystem.subsystem_id, label: relation_subsystem.subsystem_name, children: [] };
      categoryCollection[relation_subsystem.subsystem_name] = compose_category;
    };
  });

  withModuleOrganizationUserModuleList.forEach(({ relation_subsystem, name, href, ...otherAttr }) => {
    if (relation_subsystem) {
      categoryCollection[relation_subsystem.subsystem_name].children.push({ key: href, label: name, ...otherAttr });
    };
  });

  return Object.values(categoryCollection);
};