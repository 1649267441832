/* eslint-disable react/prop-types */
import { Tree } from "antd";
import React, { useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";
import { DepartmentManagerService } from "@/services/framework/DepartmentManagerService";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";
import { UserManagerService } from "@/services/framework/UserManagerService";

export function OrgDeptTreeData(props) {

  const { initSelNode } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const query_org_id = searchParams.get("org_id");
  const query_dept_id = searchParams.get("dept_id");

  const organizationManagerService: OrganizationManagerService = useDependencieEveryTime(OrganizationManagerService);
  const departmentManagerService: DepartmentManagerService = useDependencieEveryTime(DepartmentManagerService);
  const orgDeptTreeDataService: OrgDeptTreeDataService = useDependencieEveryTime(OrgDeptTreeDataService);
  const userManagerService: UserManagerService = useDependencieEveryTime(UserManagerService);

  const selectedKeys = useMemo(() => {
    if (query_org_id) {
      return [`org:${query_org_id}`];
    };
    if (query_dept_id) {
      return [`dept:${query_dept_id}`];
    };
    if (initSelNode) {
      selectDeal({ node: initSelNode });
      return [initSelNode.key];
    }
    return [];
  }, [query_org_id, query_dept_id, initSelNode]);



  async function selectDeal(event) {
    if (event.node.type === "dept") {
      const { dept_id } = event.node;
      await organizationManagerService.clearOrganizationInfo();
      setSearchParams({ dept_id });
      return false;
    };
    if (event.node.type === "org") {
      const { org_id } = event.node;
      await departmentManagerService.clearDepartmentInfo();
      setSearchParams({ org_id });
      return false;
    };
  }

  const handleSelect = useCallback(async (valueList, event) => {
    await selectDeal(event);
  }, []);

  if (!orgDeptTreeDataService.treeData) {
    return null;
  };

  return (
    <Tree
      showIcon
      blockNode
      defaultExpandAll
      selectedKeys={selectedKeys}
      style={{ padding: 20 }}
      onSelect={handleSelect}
      treeData={orgDeptTreeDataService.treeData}
    />
  )
};


OrgDeptTreeData.propTypes = {

};

OrgDeptTreeData.defaultProps = {
  initSelNode: null
};