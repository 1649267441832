// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* 修改antd的默认样式 */
/* styles.css */
.ant-layout-sider-trigger {
    background-color: #1677ff !important;
    /* 修改 collapsible 按钮的背景颜色 */
}

.ant-layout-sider-trigger:hover {
    background-color: #1677ff !important;
    opacity: 0.6;

    /* 悬停时的背景颜色 */
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,eAAe;AACf;IACI,oCAAoC;IACpC,2BAA2B;AAC/B;;AAEA;IACI,oCAAoC;IACpC,YAAY;;IAEZ,aAAa;AACjB","sourcesContent":["/* 修改antd的默认样式 */\n/* styles.css */\n.ant-layout-sider-trigger {\n    background-color: #1677ff !important;\n    /* 修改 collapsible 按钮的背景颜色 */\n}\n\n.ant-layout-sider-trigger:hover {\n    background-color: #1677ff !important;\n    opacity: 0.6;\n\n    /* 悬停时的背景颜色 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
