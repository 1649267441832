/* eslint-disable react/prop-types */
import React from "react";

import css from "./style.module.less";

import backgroud from "../../assets/left_bg.png";

export function BannerBlock(props) {
  return (
    <div className={css.container} style={{ width: "100%", height: "100%", backgroundImage: `url(${backgroud})` }}>
      <div className={css.information}>
        <a href="http://beian.miit.gov.cn/">皖ICP备2024033640号-2</a>
      </div>
    </div>
  )
};


BannerBlock.propTypes = {

};

BannerBlock.defaultProps = {

};