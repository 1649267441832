import { Result, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { MenuService } from "@/services/framework/MenuService";

import { RedirectWithRole } from "@/services/router/RedirectWithRole";

export function NotFoundPage(props) {

  const navigate = useNavigate();
  const location = useLocation();

  const [noMatch, setNoMatch] = useState(false);

  const subSystemService: SubSystemService = useDependencieEveryTime(SubSystemService);
  const redirectWithRole: RedirectWithRole = useDependencieEveryTime(RedirectWithRole);

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  const menuService: MenuService = useDependencieEveryTime(MenuService);

  useEffect(() => {
    requestAnimationFrame(async () => {
      /** 在已经登陆的情况下又调转到了登陆页,此时应该回到默认页 **/
      if (location.pathname === "/login") {
        window.location.pathname = redirectWithRole.getDefaultPageLinkHref();
        return false;
      };
      if (location.pathname === "/") {
        const subSystemList = await subSystemService.getSubSystemList();
        const firstLinkHref = (await menuService.getFirstLinkWithSubSystemId(subSystemList[0].subsystem_id)) || redirectWithRole.getDefaultPageLinkHref();
        window.location.pathname = firstLinkHref;
        return false;
      };
      setNoMatch(true);
    });
  }, [location, menuService, redirectWithRole]);

  const handleClick = useCallback(() => {
    navigate(redirectWithRole.getDefaultPageLinkHref(), { replace: true });
  }, [userInfo]);

  if (noMatch) {
    return (
      //@ts-ignore
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary" onClick={handleClick}>Back Home</Button>}
      />
    )
  };

  return (
    <Outlet></Outlet>
  );
};


NotFoundPage.propTypes = {

};

NotFoundPage.defaultProps = {

};