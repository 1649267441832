/* eslint-disable react/prop-types */
import { useParams, useNavigate } from "react-router-dom";
import { Flex, Button, Space, Divider, message } from "antd";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { FullModuleList } from "./components/FullModuleList";
import { AddModuleLayer } from "./floatlayers/AddModuleLayer";
import { UpdateModuleLayer } from "./floatlayers/UpdateModuleLayer";
import { DeleteModuleLayer } from "./floatlayers/DeleteModuleLayer";
import { PageContainer } from "@/components/PageContainer";


import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";


export function ModuleConfigPage(props) {

  const navigate = useNavigate();

  const { subsystem_id, module_group_id } = useParams();

  const [dataSource, setDataSource] = useState([]);

  /** 获取所有的模块清单 **/
  const handleDataSource = useCallback(async () => {
    const moduleList = await request({
      method: "GET",
      url: "/api/gmecamp/module/getModuleList",
      params: { subsystem_id, module_group_id }
    });
    setDataSource(moduleList);
  }, []);

  useEffect(() => {
    requestAnimationFrame(handleDataSource);
  }, [handleDataSource]);

  const handleModuleSort = useCallback(async (sortableResult) => {
    try {
      await request({
        method: "POST",
        url: "/api/gmecamp/module/updateModuleSort",
        data: sortableResult
      });
      await handleDataSource();
      message.success({ key: "handleModuleSort", content: "排序成功!!!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleModuleSort", content: error.message, duration: 3 });
    };
  }, [handleDataSource]);

  /** 跳转到静态资源部署页面 **/
  const handleDeployStatic = useCallback((record) => {
    navigate(`/main/module/deploy/${record.module_id}`);
  }, [navigate]);

  /** 添加模块 **/
  const addModuleController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleInsertModule = useCallback(async (form) => {
    try {
      const fields = await form.validateFields();
      message.loading({ key: "handleInsertModule", content: "正在新增组织模块...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gemcamp/module/addModule",
        data: { ...fields }
      });
      await addModuleController.close();
      await handleDataSource();
      message.success({ key: "handleInsertModule", content: "新增成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleInsertModule", content: error.message, duration: 3 });
    };
  }, [handleDataSource, addModuleController]);

  /** 更新模块参数 **/
  const updateModuleController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleChangeModule = useCallback(async (form, record) => {
    try {
      const fields = await form.validateFields();
      message.loading({ key: "handleChangeModule", content: "正在更新组织模块...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/module/updateModule",
        data: { module_id: record.module_id, ...fields }
      });
      await updateModuleController.close();
      await handleDataSource();
      message.success({ key: "handleChangeModule", content: "更新成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleChangeModule", content: error.message, duration: 3 });
    };
  }, [handleDataSource, updateModuleController]);

  /** 删除模块 **/
  const deleteModuleController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleDeleteModule = useCallback(async (record) => {
    try {
      message.loading({ key: "handleDeleteModule", content: "正在删除组织模块...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/module/deleteModule",
        data: { module_id: record.module_id }
      });
      await deleteModuleController.close();
      await handleDataSource();
      message.success({ key: "handleDeleteModule", content: "删除成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleDeleteModule", content: error.message, duration: 3 });
    };
  }, [handleDataSource, deleteModuleController]);

  const sortableDataSource = useMemo(() => {
    return (dataSource || []).sort((prev, next) => {
      return prev.module_pos - next.module_pos;
    });
  }, [dataSource]);

  return (
    <PageContainer>
      <Flex vertical gap={20}>
        <Divider>负责模块管理和部署</Divider>
        <Space split={<Divider type="vertical" />}>
          <Button type="primary" onClick={() => addModuleController.open()}>
            添加模块
          </Button>
        </Space>
        <FullModuleList
          dataSource={sortableDataSource}
          onModuleSort={handleModuleSort}
          onDeployStatic={handleDeployStatic}
          onChangeModule={(record) => updateModuleController.open(record)}
          onDeleteModule={(record) => deleteModuleController.open(record)}
        />
        <AddModuleLayer subsystem_id={Number(subsystem_id)} module_group_id={Number(module_group_id)} controller={addModuleController} onSubmit={handleInsertModule} />
        <UpdateModuleLayer subsystem_id={Number(subsystem_id)} module_group_id={Number(module_group_id)} controller={updateModuleController} onSubmit={handleChangeModule} />
        <DeleteModuleLayer subsystem_id={Number(subsystem_id)} module_group_id={Number(module_group_id)} controller={deleteModuleController} onSubmit={handleDeleteModule} />
      </Flex>
    </PageContainer>
  )
};


ModuleConfigPage.propTypes = {

};

ModuleConfigPage.defaultProps = {

};