/* eslint-disable react/prop-types */
import React from "react";
// import propTypes from "prop-types";
// import classnames from "classnames";

// import css from "./style.scss";
// import css from "./style.less";

export function PageContainer(props) {
  const { children } = props;
  return (
    <div style={{ padding: 20 }}>
      {children}
    </div>
  )
};

PageContainer.propTypes = {

};

PageContainer.defaultProps = {

};