/* eslint-disable react/prop-types */
import { TreeSelect } from "antd";
import { traverse } from "object-traversal";
import React, { useState, useEffect } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { UserInfoService } from "@/services/framework/UserInfoService";

import { request } from "@/utils/request";
import { isGaeaRole } from "@/utils/checkRole";

export function SelectParentOrganization(props) {

  const { exclude } = props;

  const [organizationInfoList, setOrganizationInfoList] = useState(null);
  const $UserInfoService = useDependencieEveryTime(UserInfoService);

  useEffect(() => {
    const cancelToken = requestAnimationFrame(async () => {
      const { org_id, role } = $UserInfoService.userInfo;
      let params: any = { pageNumber: 1, pageSize: 9999 };
      let comboList = [{ title: "未关联", value: -1 }];
      if (!isGaeaRole(role)) {
        params.org_id = org_id;
        comboList = [];
      }
      const { list } = await request({
        method: "GET",
        url: "/api/gmecamp/org/getOrganizationRelationList",
        params
      });
      const cloneList = JSON.parse(JSON.stringify(list));
      traverse(cloneList, ({ parent, key, value }) => {
        if (key === "org_id") {
          parent.value = value;
          if (value === exclude) {
            parent.disabled = true;
          };
        };
        if (key === "org_name") {
          parent.title = value;
        };
      });
      comboList = comboList.concat(cloneList);
      setOrganizationInfoList(comboList);
    });
    return () => {
      cancelAnimationFrame(cancelToken);
    };
  }, []);

  if (!organizationInfoList) {
    return null;
  };

  return (
    <TreeSelect treeDefaultExpandAll treeData={organizationInfoList} {...props} placeholder="请选择关联的公司" allowClear />
  )
};


SelectParentOrganization.propTypes = {

};

SelectParentOrganization.defaultProps = {
  exclude: null
};