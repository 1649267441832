import { request } from "@/utils/request";

export async function executeUpload(formLogoField) {
  if (!formLogoField) {
    return undefined;
  };
  const uploadResourceList = await Promise.all(formLogoField.map(async (singleFile) => {
    const { file } = singleFile;
    if (!file) {
      return false;
    };
    const formData = new FormData();
    formData.append("file", file);
    const resource = await request({
      method: "POST",
      url: "/api/gmecamp/resource/uploadResource",
      data: formData
    });
    return resource;
  }));
  const filterUploadResourceList = uploadResourceList.filter(Boolean);
  if (filterUploadResourceList.length) {
    return filterUploadResourceList[0].id;
  };
  return undefined;
};