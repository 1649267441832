/* eslint-disable react/prop-types */
// import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { SelectUserLevel } from "@/components/OrganizationUserComponent/SelectUserLevel";
import { SelectDepartment } from "@/components/OrganizationUserComponent/SelectDepartment";
import { SelectUserModule } from "@/components/OrganizationUserComponent/SelectUserModule";
import { SelectOrganization } from "@/components/OrganizationUserComponent/SelectOrganization";

import { usernameValidator } from "@/utils/usernameValidator";
import { FloatController } from "@/utils/FloatController";

export function UpdateUserLayer(props) {
  const { controller, onSubmit } = props;

  const [form] = Form.useForm();

  const [status, setStatus] = useState(false);
  const [organizationUserInfo, setOrganizationUserInfo]: any = useState({});

  const [selOrgId, setSelOrgId] = useState(Number(organizationUserInfo?.relation_organization?.org_id || -1));

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputOrganizationUserInfo) => {
      setStatus(true);
      setOrganizationUserInfo(inputOrganizationUserInfo);
      form.setFieldsValue({
        username: inputOrganizationUserInfo?.username,
        org_id: inputOrganizationUserInfo.relation_organization?.org_id,
        dept_id: inputOrganizationUserInfo.relation_department?.dept_id,
        role: inputOrganizationUserInfo?.role,
        modules: inputOrganizationUserInfo?.modules
      })
      setSelOrgId(Number(inputOrganizationUserInfo?.relation_organization?.org_id));

    });
    controller.registryConfirmHandler(() => {
      setStatus(false);
      setOrganizationUserInfo({});
    });
    controller.registryCancelHandler(() => {
      setStatus(false);
      setOrganizationUserInfo({});
    });
    controller.registryCloseHandler(() => {
      setStatus(false);
      setOrganizationUserInfo({});
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  const handleFormChange = (changedValues, allValues) => {
    if (changedValues.org_id) {
      setSelOrgId(allValues.org_id);
      form.setFieldsValue({
        dept_id: -1
      })
    }
  }

  function mergeModules(orgModules) {
    const modules = organizationUserInfo.modules.filter((item) => orgModules.some((orgItem) => orgItem.module_id === item));
    form.setFieldsValue({
      modules
    })
  }

  const onFinish = (values) => {
    onSubmit(organizationUserInfo, form)
  };

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="更新用户信息" onCancel={handleCancel}>
      <Form layout="vertical" form={form} onValuesChange={handleFormChange} onFinish={onFinish}>
        <Form.Item label="用户账号" name="username" rules={[{ required: true, validator: usernameValidator() }]} >
          <Input disabled allowClear placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item label="关联机构" name="org_id" rules={[{ required: true }]} >
          <SelectOrganization />
        </Form.Item>
        <Form.Item label="关联部门" name="dept_id"  >
          <SelectDepartment org_id={selOrgId} />
        </Form.Item>
        <Form.Item label="权限等级" name="role" rules={[{ required: true }]} >
          <SelectUserLevel />
        </Form.Item>
        <Form.Item label="关联模块" name="modules" rules={[{ required: false }]} >
          <SelectUserModule org_id={selOrgId} mergeModules={mergeModules} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {/* {(form) => {
            return 
          }} */}
          <Button htmlType="submit" block type="primary" >确认提交</Button>
        </Form.Item>
      </Form>
    </Modal>
  )
};


UpdateUserLayer.propTypes = {
  // organizationInfo: propTypes.instanceOf(Object),
  // departmentInfo: propTypes.instanceOf(Object),
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateUserLayer.defaultProps = {
  onSubmit() { }
};