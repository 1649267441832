/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import propTypes from "prop-types";

import { request } from "@/utils/request";

/**
 * 该组件的作用是从分配给组织的全量模块中再选择出一部分模块
 * 分配给其余用户
 * **/
export function SelectUserModule(props) {
  const { org_id, mergeModules } = props;

  const [modules, setModules] = useState([]);

  useEffect(() => {
    requestAnimationFrame(async () => {
      const result = await request({
        method: "GET",
        url: "/api/gmecamp/module/getAssignModuleWithOrganization",
        params: { organization_id: org_id }
      });
      setModules(result);
      mergeModules(result);
    });
  }, [org_id]);

  return (
    <Checkbox.Group {...props} placeholder="请选择为用户开放的模块">
      {/** @ts-ignore **/}
      {(modules).map(({ module_id, name }) => {
        return (
          /** @ts-ignore **/
          <Checkbox key={module_id} value={module_id}>
            {name}
          </Checkbox>)
      })}
    </Checkbox.Group>
  )
};


SelectUserModule.propTypes = {
  org_id: propTypes.number,
  mergeModules: propTypes.func
};

SelectUserModule.defaultProps = {

};