import path from "path";
import { fromPairs } from "lodash";
import { match } from "path-to-regexp";
import { injectable, inject } from "inversify";

import { UserInfoService } from "@/services/framework/UserInfoService";
import { flatMenuWithAssignModule } from "@/utils/flatMenuWithAssignModule";

export type subSystemList = {
  subsystem_pos: number,
  subsystem_id: number,
  label: string,
  key: string
}[];

@injectable()
export class SubSystemService {

  private matchPattern = match("/:subsystem_id/(.*)?");

  constructor(
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService
  ) { };

  /** 当前子系统ID是否为main **/
  public routerIsMain(subsystem_id) {
    if (subsystem_id === "main") {
      return true;
    };
    return false;
  };

  /** 当前子系统ID是否为login **/
  public routerIsLogin(subsystem_id) {
    if (subsystem_id === "login") {
      return true;
    };
    return false;
  };

  /** 根据path-to-regexp的匹配表达式获取子系统的ID **/
  public getSubSystemId() {
    const { params: routerParams }: any = this.matchPattern(location.pathname);
    if (routerParams) {
      if (!routerParams.subsystem_id) {
        return false;
      };
      return routerParams.subsystem_id;
    };
    return false;
  };

  /** 根据用户分配到的模块来计算子系统的列表 **/
  public getSubSystemList(): subSystemList {
    const userAssignModules = this.$UserInfoService.userAssignModules;
    const basicItems = [{
      subsystem_pos: 9999,
      subsystem_id: 9999,
      label: "系统设置",
      key: "/main"
    }];
    const pickSubSystemWithModuleCategory = fromPairs(userAssignModules.map(({ subsystem: { subsystem_id, subsystem_name, subsystem_pos } }) => {
      return [subsystem_id, {
        subsystem_pos,
        subsystem_id,
        label: subsystem_name,
        key: path.join("/", String(subsystem_id))
      }];
    }));
    const pickSubSystemWithModuleCategoryList = Object.values(pickSubSystemWithModuleCategory);
    const comboSubSystemList = ([
      ...pickSubSystemWithModuleCategoryList.sort((prev: any, next: any) => prev.subsystem_pos - next.subsystem_pos),
      ...basicItems
    ] as subSystemList);
    return comboSubSystemList;
  };

  /** 获取第一个子系统下的第一个link_href **/
  public getFirstLinkHrefByFirstSubSystem() {
    const userAssignModules = this.$UserInfoService.userAssignModules;
    const firstSystemList: any[] = Object.values(flatMenuWithAssignModule(userAssignModules)).flat();
    if (firstSystemList[0]) {
      return firstSystemList[0].key;
    };
    return false;
  };

  /** 根据subsystem_id获取第一个link_href **/
  public getFirstLinkHrefWithSubSystemId(subsystem_id) {
    const userAssignModules = this.$UserInfoService.userAssignModules;
    const allAssignModule = flatMenuWithAssignModule(userAssignModules);
    const subSystemNavigateList = allAssignModule[subsystem_id];
    if (subSystemNavigateList[0]) {
      return subSystemNavigateList[0].key;
    };
    return false;
  };

};