/* eslint-disable react/prop-types */
import { useParams } from "react-router-dom";
import { CheckCard } from "@ant-design/pro-components";
import { Flex, Button, Divider, Collapse, message } from "antd";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { groupWithCategory } from "@/utils/groupWithCategory";
import { request } from "@/utils/request";
import { BackwardOutlined } from "@ant-design/icons";
import { PageContainer } from "@/components/PageContainer";

import { ModuleService } from "@/services/framework/ModuleService";
import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";

export function AssignModulePage(props) {

  const { organization_id } = useParams();
  const [moduleList, setModuleList] = useState([]);
  const [currentAssignModuleList, setCurrentAssignModuleList] = useState(null);
  const [defaultAssignModuleList, setDefaultAssignModuleList] = useState(null);

  const moduleService: ModuleService = useDependencieEveryTime(ModuleService);
  const organizationManagerService: OrganizationManagerService = useDependencieEveryTime(OrganizationManagerService);

  /** 获取所有配置好的模块 **/
  const handleFetchModuleList = useCallback(async () => {
    let moduleList = await request({
      method: "GET",
      url: "/api/gmecamp/module/getModuleList"
    });
    //获取父组织的模块列表
    // let parnetOrgId = organizationManagerService?.organizationInfo?.relation_parent?.org_id;
    // if (parnetOrgId) {
    //   const parentModuleList = await request({
    //     method: "GET",
    //     url: "/api/gmecamp/module/getAssignModuleWithOrganization",
    //     params: { organization_id: parnetOrgId }
    //   });
    //   console.log("parentModuleList", parentModuleList);
    //   const idsToKeep = new Set(parentModuleList.map(item => item.module_id));
    //   moduleList = moduleList.filter(item => idsToKeep.has(item.module_id));
    // }

    // console.log("moduleList", moduleList);
    setModuleList(moduleList);
  }, []);

  /** 经过分组处理之后的模块列表 **/
  const groupModuleList = useMemo(() => {
    return groupWithCategory(moduleList);
  }, [moduleList]);

  /** 默认展开的手风琴 **/
  const activeKey = useMemo(() => {
    return groupModuleList.map(({ key }: any) => key);
  }, [groupModuleList]);

  /** 获取当前给组织分配的模块 **/
  const getModuleWithOrganization = useCallback(async () => {
    await moduleService.getAssignModuleWithOrganization(organization_id);
    setDefaultAssignModuleList(moduleService.orgModules.map(({ module_id }) => module_id));
  }, [organization_id]);

  /**将模块分配给当前组织 **/
  const handleAssignModule = useCallback(async () => {
    try {
      message.loading({ key: "handleApplyModule", content: "正在修改组织模块...", duration: 0 });
      await moduleService.assginModuleToOrganization(organization_id, currentAssignModuleList);
      await getModuleWithOrganization();
      message.success({ key: "handleApplyModule", content: "修改成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleApplyModule", content: error.message, duration: 3 });
    };
  }, [currentAssignModuleList, organization_id, getModuleWithOrganization]);

  useEffect(() => {
    requestAnimationFrame(handleFetchModuleList);
    requestAnimationFrame(getModuleWithOrganization);
  }, [handleFetchModuleList, getModuleWithOrganization]);

  /** 比较选择的前后是否存在差异 **/
  const isDiffSelect = useMemo(() => {
    if (!defaultAssignModuleList) {
      return false;
    };
    if (!currentAssignModuleList) {
      return false;
    };
    const defaultFormatString = defaultAssignModuleList.sort().join();
    const currentFormatString = currentAssignModuleList.sort().join();
    return defaultFormatString !== currentFormatString;
  }, [defaultAssignModuleList, currentAssignModuleList]);

  if (!defaultAssignModuleList) {
    return null;
  };

  return (
    <PageContainer>
      <Flex vertical gap={20}>
        <Button type="primary" icon={<BackwardOutlined />} onClick={() => window.history.back()} style={{ marginRight: "auto" }}>
          返回
        </Button>
        <Divider>组织的模块配额</Divider>
        <CheckCard.Group multiple defaultValue={defaultAssignModuleList} onChange={setCurrentAssignModuleList}>
          <Flex vertical gap={20}>
            {groupModuleList.map(({ key, label, children }: any) => {
              return (
                <Collapse
                  key={key}
                  activeKey={activeKey}
                  items={[{
                    key, label,
                    children: children.map(({ module_id, label, rule }) => {
                      return (
                        <CheckCard key={module_id} value={module_id} title={label} description={rule} />
                      )
                    })
                  }]}
                />
              )
            })}
          </Flex>
        </CheckCard.Group>
        <Button type="primary" disabled={!isDiffSelect} onClick={handleAssignModule}>
          应用当前模块配置
        </Button>
      </Flex>
    </PageContainer>
  )
};


AssignModulePage.propTypes = {

};

AssignModulePage.defaultProps = {

};
