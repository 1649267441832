import axios from "axios";
import React from "react";

const remoteResourceCacheTable = {};

export async function loadRemoteComponent(remoteResource) {

  /** 缓存表中存在资源的话直接返回 **/
  if (remoteResourceCacheTable[remoteResource]) {
    return React.lazy(() => {
      return new Promise((resolve) => {
        resolve({ default: remoteResourceCacheTable[remoteResource] } as any);
      });
    });
  };

  let manifastResponse;
  /** 缓存表中不存在资源的话需要加载 **/
  const serverBasicURL = remoteResource.replace("manifest.json", "").replace(/\/%/ig, "");
  try {
    const { data } = await axios({
      method: "GET",
      url: `${serverBasicURL}/manifest.json`,
    });
    manifastResponse = data;
  } catch (error) {
    throw new Error("远程模块加载失败");
  };

  /** 动态加载CSS **/
  if (manifastResponse["main.css"]) {
    const styleElement = document.createElement("link");
    styleElement.type = "text/css";
    styleElement.rel = "stylesheet";
    styleElement.href = `${serverBasicURL}/${manifastResponse["main.css"]}`;
    document.body.appendChild(styleElement);
  };

  /** 动态加载JavaScript **/
  return React.lazy(() => new Promise((resolve, reject) => {
    const scriptElement = document.createElement("script");
    scriptElement.src = `${serverBasicURL}/${manifastResponse["main.js"]}`;
    scriptElement.onload = () => {
      resolve({ default: (window[manifastResponse.projectName] as any).CreateApplication } as any);
      remoteResourceCacheTable[remoteResource] = (window[manifastResponse.projectName] as any).CreateApplication;
    };
    scriptElement.onerror = () => {
      reject(new Error("远程模块加载失败"));
    };
    document.body.appendChild(scriptElement);
  }))
};