/* eslint-disable react/prop-types */
import { Menu, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useMemo, useCallback } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { ThemeService } from "@/services/framework/ThemeService";

/** 根据配额的模块计算出子系统导航 **/
export function SubSystemNavigation(props) {
  const navigate = useNavigate();

  const subSystemService: SubSystemService = useDependencieEveryTime(SubSystemService);
  const subSystemId = subSystemService.getSubSystemId();

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  /** 从moduleCategory中pick出相应的子系统 **/
  const navigationItems: any = useMemo(() => {
    return subSystemService.getSubSystemList();
  }, [subSystemService]);

  const handleClick = useCallback((current) => {
    navigate(current.key);
  }, [navigate]);

  return (
    <ConfigProvider theme={{ components: themeService.getThemeConfig().subsystemThemeConfig }}>
      <div style={{ flex: 1 }}>
        <Menu
          theme="dark"
          mode="horizontal"
          items={navigationItems}
          selectedKeys={[`/${subSystemId}`]}
          onClick={handleClick}
        />
      </div>
    </ConfigProvider>

  )
};


SubSystemNavigation.propTypes = {

};

SubSystemNavigation.defaultProps = {

};