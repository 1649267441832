/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import React, { useMemo } from "react";
import { Space, Table, Button } from "antd";

export function ServiceRuleListTable(props) {

  const { dataSource, onUpdateProxyRule, onDeleteProxyRule, onFreezeProxyRule, onRecordProxyRule } = props;

  const columns: any[] = useMemo(() => {
    return [{
      key: "disabled",
      align: "center",
      title: "是否冻结",
      dataIndex: "disabled",
      render(value) {
        if (value) {
          return <span style={{ color: "red" }}>已冻结</span>
        };
        return <span>正常</span>
      }
    }, {
      key: "service_id",
      align: "center",
      title: "规则ID",
      dataIndex: "service_id"
    }, {
      key: "pattern",
      align: "center",
      title: "命中规则",
      dataIndex: "pattern"
    }, {
      key: "target_url",
      align: "center",
      title: "目标服务器",
      dataIndex: "target_url"
    }, {
      key: "description",
      align: "center",
      title: "规则的简易描述",
      dataIndex: "description"
    }, {
      key: "action",
      align: "center",
      title: "目标服务器",
      render(currentRecord) {
        return (
          <Space size={20}>
            <Button type="primary" onClick={() => onUpdateProxyRule(currentRecord)}>修改</Button>
            {currentRecord.disabled ? (
              <Button danger type="default" onClick={() => onRecordProxyRule(currentRecord)}>恢复</Button>
            ) : (
              <Button type="primary" onClick={() => onFreezeProxyRule(currentRecord)}>冻结</Button>
            )}
            <Button danger type="primary" onClick={() => onDeleteProxyRule(currentRecord)}>删除</Button>
          </Space>
        )
      }
    }];
  }, []);

  return (
    <Table bordered rowKey="service_id" columns={columns} dataSource={dataSource} />
  )
};


ServiceRuleListTable.propTypes = {
  onUpdateProxyRule: propTypes.func,
  onDeleteProxyRule: propTypes.func,
  onFreezeProxyRule: propTypes.func,
  onRecordProxyRule: propTypes.func,
};

ServiceRuleListTable.defaultProps = {
  dataSource: [],
  onUpdateProxyRule() { },
  onDeleteProxyRule() { },
  onFreezeProxyRule() { },
  onRecordProxyRule() { },
};