/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
// import propTypes from "prop-types";
// import classnames from "classnames";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { OrganizationService } from "@/services/framework/OrganizationService";

import css from "./style.module.less";

export function LogoBlock(props) {

  const organizationService = useDependencieEveryTime(OrganizationService);
  const { organizationInfoData } = organizationService;

  const logoUrl = useMemo(() => {
    if (organizationInfoData.relation_resource) {
      return `url(${organizationInfoData.relation_resource.url})`;
    };
    return undefined
  }, [organizationInfoData]);

  return (
    <div className={css.container}>
      <div className={css.logo} style={{ backgroundImage: logoUrl }}></div>
    </div>
  )
};


LogoBlock.propTypes = {

};


LogoBlock.defaultProps = {

};