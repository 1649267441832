import { message } from "antd";
import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";


@injectable()
export class UserManagerService {

  public userList: any[] | null = null;

  public modifyPasswordController = new FloatController();

  public addUserController = new FloatController();

  public updateUserController = new FloatController();

  public deleteUserController = new FloatController();

  constructor(
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  public async clearUserList() {
    this.userList = null;
    await this.$GlobalUIRender.executeRender();
  };

  /** 根据机构ID获取用户列表 **/
  public async getUserListWithOrganization(org_id: number) {
    try {
      await this.clearUserList();
      const { list } = await request({
        method: "GET",
        url: "/api/gmecamp/org/getOrganizationUserList",
        params: { org_id, pageNumber: 1, pageSize: 9999 }
      });
      this.userList = list;
      await this.$GlobalUIRender.executeRender();
    } catch (error) {
      message.error({ key: "getUserListWithOrganization", content: error.message, duration: 3 });
    };
  };

  /** 根据部门ID获取用户列表 **/
  public async getUserListWithDepartment(dept_id: number) {
    try {
      await this.clearUserList();
      const departmentUserList = await request({
        method: "GET",
        url: "/api/gmecamp/department/getDepartmentUserList",
        params: { dept_id }
      });
      this.userList = departmentUserList;
      await this.$GlobalUIRender.executeRender();
    } catch (error) {
      message.error({ key: "getUserListWithDepartment", content: error.message, duration: 3 });
    };
  };

  /** 仅基于企业添加用户 **/
  public async addUserWithOrg(contextInfo, form) {
    try {
      const { org_id } = contextInfo;
      const { dept_id, ...formFieldResult } = await form.validateFields();
      message.loading({ key: "handleAddUserWithOrg", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/user/addUser",
        data: { org_id, dept_id: (dept_id === -1 || dept_id === undefined) ? 0 : dept_id, ...formFieldResult }
      });
      await this.addUserController.close();
      await this.getUserListWithOrganization(org_id);
      message.success({ key: "handleAddUserWithOrg", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleAddUserWithOrg", content: error.message, duration: 3 });
    };
  };

  /** 在当前部门下添加用户 **/
  public async addUser(contextInfo, form) {
    try {
      const { relation_organization } = contextInfo;
      const { dept_id, ...formFieldResult } = await form.validateFields();
      message.loading({ key: "handleAddUser", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/user/addUser",
        data: { org_id: relation_organization.org_id, dept_id, ...formFieldResult }
      });
      await this.addUserController.close();
      await this.getUserListWithDepartment(dept_id);
      message.success({ key: "handleAddUser", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleAddUser", content: error.message, duration: 3 });
    };
  };

  /** 更新当前机构的用户信息 **/
  public async updateUser(userInfo, form) {
    try {
      const { modules, ...formFieldResult } = await form.validateFields();
      message.loading({ key: "handleUpdateUser", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/user/modifyUser",
        data: { user_id: userInfo.user_id, ...formFieldResult }
      });
      await request({
        method: "POST",
        url: "/api/gmecamp/module/assginModuleToUser",
        data: { user_id: userInfo.user_id, modules }
      });
      await this.updateUserController.close();
      message.success({ key: "handleUpdateUser", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleUpdateUser", content: error.message, duration: 3 });
    };
  };

  /** 删除机构用户 **/
  public async deleteUser(currentRecord) {
    try {
      message.loading({ key: "handleDeleteUser", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/user/deleteUser",
        data: { user_id: currentRecord.user_id }
      });
      await this.deleteUserController.close();
      message.success({ key: "handleDeleteUser", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleDeleteUser", content: error.message, duration: 3 });
    };
  };

  /** 修改用户密码 **/
  public async modifyPassword(record, form) {
    try {
      const { password, oldPassword } = await form.validateFields();
      message.loading({ key: "handleModifyPassword", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/user/modifyUserPassword",
        data: {
          user_id: record.user_id, password, oldPassword
        }
      });
      await this.modifyPasswordController.close();
      message.success({ key: "handleModifyPassword", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "handleModifyPassword", content: error.message, duration: 3 });
    };
  };

};