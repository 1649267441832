/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import React, { useMemo, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Flex, Badge, Button, Descriptions, Image, Tag } from "antd";
import dayjs from "dayjs"

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { isAdmin, isGaeaRole } from "@/utils/checkRole";

import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";
import { DepartmentManagerService } from "@/services/framework/DepartmentManagerService";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { ModuleService } from "@/services/framework/ModuleService";

import { UpdateOrganizationLayer } from "../OrganizationManagerPage/floatlayers/UpdateOrganizationLayer";
import { FreezeOrganizationLayer } from "../OrganizationManagerPage/floatlayers/FreezeOrganizationLayer";
import { RecoverOrganizationLayer } from "../OrganizationManagerPage/floatlayers/RecoverOrganizationLayer";
import { DelOrganizationLayer } from "../OrganizationManagerPage/floatlayers/DelOrganizationLayer";

import { AddUserLayer } from "../floatlayers/AddUserLayer";
import { AssignUserLayer } from "../floatlayers/AssignUserLayer";
import { AddDepartmentLayer } from "../floatlayers/AddDepartmentLayer";
import { UpdateDepartmentLayer } from "../floatlayers/UpdateDepartmentLayer";
import { DeleteDepartmentLayer } from "../floatlayers/DeleteDepartmentLayer";

export function OrgDeptDespLayer(props) {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const query_org_id = searchParams.get("org_id");
    const query_dept_id = searchParams.get("dept_id");

    const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
    const { userInfo } = userInfoService;

    const organizationManagerService: OrganizationManagerService = useDependencieEveryTime(OrganizationManagerService);
    const { organizationInfo, updateOrganizationController, recoverOrganizationController, frozenOrganizationController, delOrganizationController,
        updateOrganization, frozenOrganization, recoverOrganization, delOrganization, getOrganizationInfo
    } = organizationManagerService;


    const departmentManagerService: DepartmentManagerService = useDependencieEveryTime(DepartmentManagerService);
    const { departmentInfo } = departmentManagerService;

    const userManagerService: UserManagerService = useDependencieEveryTime(UserManagerService);

    const moduleService: ModuleService = useDependencieEveryTime(ModuleService);

    const orgDeptTreeDataService: OrgDeptTreeDataService = useDependencieEveryTime(OrgDeptTreeDataService);

    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        let orgDisabled = organizationInfo?.disabled || departmentInfo?.relation_organization?.disabled;
        setDisabled(orgDisabled);
    }, [organizationInfo, departmentInfo]);


    async function flushOrg(orgId) {
        await orgDeptTreeDataService.getTreeData();
        await getOrganizationInfo.bind(organizationManagerService)(orgId);
        await userManagerService.getUserListWithOrganization(Number(orgId));
    }

    return (
        <Flex gap={20} vertical >

            <Flex gap={20} justify="space-between">

                {disabled ? (
                    <Flex gap={20} justify="space-between">
                        {organizationInfo ? <Button type="default" onClick={() => recoverOrganizationController.open(organizationInfo)}>恢复组织</Button> : <Button type="default" disabled={true}>企业已禁用</Button>}
                    </Flex>
                ) : (
                    <>
                        <Flex gap={20} justify="space-between">
                            {organizationInfo ? <>
                                <Button disabled={!isGaeaRole(userInfo.role)} type="primary" onClick={() => navigate(`/main/assign/${organizationInfo.org_id}`)}>模块配额</Button>
                                <Button type="primary" onClick={() => updateOrganizationController.open(organizationInfo)}>修改组织</Button>
                                <Button danger type="primary" onClick={() => frozenOrganizationController.open(organizationInfo)}>冻结组织</Button>
                                <Button disabled={isAdmin(userInfo.role) && organizationInfo.org_id === userInfo.org_id} danger type="primary" onClick={() => delOrganizationController.open(organizationInfo)}>删除组织</Button>
                            </> : <div></div>}
                        </Flex>
                        <Flex gap={20}>
                            <Button type="primary" onClick={() => departmentManagerService.addDepartmentController.open()}>添加部门</Button>
                            <Button disabled={!Boolean(departmentInfo?.dept_id)} type="primary" onClick={() => departmentManagerService.updateDepartmentController.open()}>更新部门</Button>
                            <Button disabled={!Boolean(departmentInfo?.dept_id)} type="primary" onClick={() => departmentManagerService.assignUserController.open()}>人员分配</Button>
                            <Button disabled={!Boolean(departmentInfo?.dept_id)} danger type="primary" onClick={() => departmentManagerService.deleteDepartmentController.open()}>删除部门</Button>
                        </Flex>
                    </>
                )}

            </Flex>

            {
                Boolean(departmentInfo?.dept_id) ? (
                    <span style={{ fontSize: "16px", fontWeight: 600 }}>{departmentInfo.dept_name}</span>
                ) : organizationInfo && (
                    <Flex vertical gap={20} justify="space-between">
                        <span style={{ fontSize: "16px", fontWeight: 600 }}>{organizationInfo.org_name}</span>
                        <Descriptions
                            bordered
                            column={6}
                            layout="vertical"
                        >
                            <Descriptions.Item span={2} label="LOGO" >
                                {/* @ts-ignore */}
                                <Image src={get(organizationInfo, "relation_resource.url")} />
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="公司名称" >
                                {organizationInfo.org_name}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="运行容量(kVA)">
                                {organizationInfo.capacity}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="有效期">
                                {dayjs(organizationInfo.expire).format('YYYY-MM-DD')}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="联系人">
                                {organizationInfo.contact || "-"}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="手机">
                                {organizationInfo.phone || "-"}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="电子邮箱">
                                {organizationInfo.email || "-"}
                            </Descriptions.Item>
                            <Descriptions.Item span={2} label="状态">
                                {organizationInfo?.disabled ? (
                                    <Badge status="error" text="禁用" />
                                ) : (
                                    <Badge status="success" text="正常" />
                                )}
                            </Descriptions.Item>
                        </Descriptions>
                    </Flex>
                )
            }

            {/* 对话框 */}

            <UpdateOrganizationLayer
                controller={updateOrganizationController}
                onSubmit={async (form, orgId) => {
                    await updateOrganization(form, orgId);
                    await moduleService.getAssignModuleWithOrganization(orgId);
                    await flushOrg(orgId)
                }}
            />
            <FreezeOrganizationLayer
                controller={frozenOrganizationController}
                onSubmit={async (orgInfo) => {
                    await frozenOrganization(orgInfo);
                    await flushOrg(orgInfo.org_id)
                }}
            />
            <RecoverOrganizationLayer
                controller={recoverOrganizationController}
                onSubmit={async (orgInfo) => {
                    await recoverOrganization(orgInfo);
                    await flushOrg(orgInfo.org_id)
                }}
            />
            <DelOrganizationLayer
                controller={delOrganizationController}
                onSubmit={async (form, org_id) => {
                    await delOrganization(form, org_id);
                    await orgDeptTreeDataService.getTreeData();
                }}
            />

            <AddUserLayer
                organizationInfo={organizationManagerService.organizationInfo}
                departmentInfo={departmentManagerService.departmentInfo}
                controller={userManagerService.addUserController}
                onAddUserWithOrg={(contextInfo, form) => userManagerService.addUserWithOrg(contextInfo, form)}
                onAddUser={(contextInfo, form) => userManagerService.addUser(contextInfo, form)}
            />
            <AssignUserLayer
                departmentInfo={departmentManagerService.departmentInfo}
                controller={departmentManagerService.assignUserController}
                onSubmit={(contextInfo, form) => departmentManagerService.assignUser(contextInfo, form)}
            />
            <AddDepartmentLayer
                organizationInfo={organizationManagerService.organizationInfo}
                departmentInfo={departmentManagerService.departmentInfo}
                controller={departmentManagerService.addDepartmentController}
                onAddDepartmentWithOrgId={(contextInfo, form) => departmentManagerService.addDepartmentWithOrg(contextInfo, form)}
                onAddDepartment={(contextInfo, form) => departmentManagerService.addDepartment(contextInfo, form)}
            />
            <UpdateDepartmentLayer
                departmentInfo={departmentManagerService.departmentInfo}
                controller={departmentManagerService.updateDepartmentController}
                onSubmit={async (departmentContextInfo, form) => { departmentManagerService.updateDepartment(departmentContextInfo, form) }}
            />
            <DeleteDepartmentLayer
                departmentInfo={departmentManagerService.departmentInfo}
                controller={departmentManagerService.deleteDepartmentController}
                onSubmit={(contextInfo) => departmentManagerService.deleteDepartment(contextInfo)}
            />

        </Flex >
    )
};


OrgDeptDespLayer.propTypes = {

};

OrgDeptDespLayer.defaultProps = {
    departmentList: []
};