/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Modal, Form, Input, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function AddSubSystemFloatLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => { setStatus(true) });
    controller.registryConfirmHandler(() => { setStatus(false) });
    controller.registryCancelHandler(() => { setStatus(false) });
    controller.registryCloseHandler(() => { setStatus(false) });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  return (
    <Modal zIndex={9999} width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="添加子系统" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="子系统名称" name="subsystem_name" rules={[{ required: true }]}>
          <Input allowClear placeholder="请输入子系统的名称" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => onSubmit(form)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AddSubSystemFloatLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

AddSubSystemFloatLayer.defaultProps = {
  onSubmit() { }
};