import { message } from "antd";
import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { request } from "@/utils/request";

@injectable()
export class UserRoleDictionary {

  private _dictionaryCacheTable = null;

  constructor(
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender,
  ) { };

  get dictionaryCacheTable() {
    return this._dictionaryCacheTable;
  };

  private _formatDictionaryCacheTable = null;

  get formatDictionaryCacheTable() {
    return this._formatDictionaryCacheTable;
  };

  public async initialize() {
    try {
      const data = await request({
        method: "GET",
        url: "/api/gmecamp/dict/getUserRoleDictionary",
      });
      this._dictionaryCacheTable = data;
      this._formatDictionaryCacheTable = {};
      data.forEach(({ label, value }) => {
        this._formatDictionaryCacheTable[value] = label;
      });
      this.$GlobalUIRender.executeRender();
    } catch (error) {
      message.error(error.message);
      throw error;
    };
  };

};