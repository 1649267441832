/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubSystemService } from "@/services/framework/SubSystemService";
import { MenuService } from "@/services/framework/MenuService";

import { RedirectWithRole } from "@/services/router/RedirectWithRole";

export function InitializePage(props) {

  const subSystemService: SubSystemService = useDependencieEveryTime(SubSystemService);
  const redirectWithRole: RedirectWithRole = useDependencieEveryTime(RedirectWithRole);
  const menuService: MenuService = useDependencieEveryTime(MenuService);

  useEffect(() => {
    requestAnimationFrame(async () => {
      const subSystemList = await subSystemService.getSubSystemList();
      const firstLinkHref = (await menuService.getFirstLinkWithSubSystemId(subSystemList[0].subsystem_id)) || redirectWithRole.getDefaultPageLinkHref();
      window.location.pathname = firstLinkHref;
      return false;
    });
  }, []);

  return (
    <div>正在跳转,请稍后... ...</div>
  )
};


InitializePage.propTypes = {

};

InitializePage.defaultProps = {

};