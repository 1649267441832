import md5 from "md5";
import { message } from "antd";
import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";
import { flatMenuWithAssignModule } from "@/utils/flatMenuWithAssignModule";

import { request } from "@/utils/request";

/**
 * 检查当前用户的登陆状态
 * **/

@injectable()
export class UserInfoService {

  public userInfo: any = false;

  public userAssignModules: any[] = null;

  constructor(
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  /** 获取用户的会话信息 **/
  public async getSessionInfo() {
    try {
      const fetchUserInfo = await request({
        method: "GET",
        url: "/api/gmecamp/user/getSessionInfo",
      });
      this.userInfo = fetchUserInfo;
      this.$GlobalUIRender.executeRender();
      return fetchUserInfo;
    } catch (error) {
      // await this.doLogout();
      // message.error({ key: "getSessionInfo", content: error.message, duration: 3 });
      throw error;
    };
  };

  /** 登陆逻辑 **/
  public async doLogin({ username, password, expired }) {
    try {
      await request({
        method: "POST",
        url: "/api/gmecamp/user/login",
        data: { username, password: md5(password + md5(password)), expired }
      });
    } catch (error) {
      // message.error({ key: "doLogin", content: error.message, duration: 3 });
      throw error;
    };
  };

  /** 登出逻辑 **/
  public async doLogout() {
    try {
      await request({
        method: "POST",
        url: "/api/gmecamp/user/logout"
      });
      // message.success({ key: "doLogout", content: "登出成功!", duration: 3 });
      if (window.location.pathname !== "/login") {
        window.location.pathname = "/login";
      };
    } catch (error) {
      // message.error({ key: "doLogout", content: error.message, duration: 3 });
      throw error;
    };
  };

  /** 获取用户分配到的模块 **/
  public async getAssignModuleWithSession() {
    try {
      const userAssignModules = await request({
        method: "GET",
        url: "/api/gmecamp/module/getAssignModuleWithSession"
      });
      this.userAssignModules = userAssignModules;
      this.$GlobalUIRender.executeRender();
      return userAssignModules;
      // const firstSystemList: any[] = Object.values(flatMenuWithAssignModule(userAssignModules)).flat();
      // if (firstSystemList[0]) {
      //   if (window.location.pathname !== firstSystemList[0].key) {
      //     window.location.pathname = firstSystemList[0].key;
      //   };
      // } else {
      //   if (window.location.pathname !== "/main/setting") {
      //     window.location.pathname = "/main/setting";
      //   };
      // };
    } catch (error) {
      message.error({ key: "getAssignModuleWithSession", content: error.message, duration: 3 });
      throw error;
    };
  };

};