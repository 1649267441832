/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button, DatePicker } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { UploadImage, getUploadInitialValue } from "@/components/SuperUserComponent/UploadImage";
import { SelectParentOrganization } from "@/components/SuperUserComponent/SelectParentOrganization";

import { FloatController } from "@/utils/FloatController";
import dayjs from "dayjs"

export function UpdateOrganizationLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);
  const [organizationInfo, setOrganizationInfo]: any[] = useState({});

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputOrganizationInfo) => {
      setOrganizationInfo(inputOrganizationInfo);
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setOrganizationInfo({});
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setOrganizationInfo({});
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setOrganizationInfo({});
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  const handleSubmit = useCallback(async (from, org_id) => {
    await onSubmit(from, org_id);
    await controller.close();
  }, [controller]);

  return (
    <Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="更新组织信息" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="组织LOGO" name="logo_id" initialValue={getUploadInitialValue(organizationInfo.relation_resource)}>
          <UploadImage />
        </Form.Item>
        <Form.Item label="上级单位" name="parent_org_id" rules={[{ required: false }]} initialValue={get(organizationInfo, "relation_parent.org_id", undefined)}>
          <SelectParentOrganization exclude={organizationInfo.org_id} />
        </Form.Item>
        <Form.Item label="组织名称" name="org_name" rules={[{ required: true }]} initialValue={organizationInfo.org_name}>
          <Input allowClear placeholder="请输入组织名称" />
        </Form.Item>
        <Form.Item label="运行容量(kVA)" name="capacity" rules={[{ required: true }]} initialValue={organizationInfo.capacity}>
          <Input allowClear placeholder="请输入运行容量(kVA)" />
        </Form.Item>
        <Form.Item label="有效期" name="expire" rules={[{ required: true }]} initialValue={dayjs(organizationInfo.expire)}>
          <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }}></DatePicker>
        </Form.Item>
        <Form.Item label="联系人" name="contact" rules={[{ required: true }]} initialValue={organizationInfo.contact}>
          <Input allowClear placeholder="请输入联系人" />
        </Form.Item>
        <Form.Item label="手机" name="phone" rules={[{ required: true }]} initialValue={organizationInfo.phone}>
          <Input allowClear placeholder="请输入手机" />
        </Form.Item>
        <Form.Item label="电子邮箱" name="email" rules={[{ required: true }]} initialValue={organizationInfo.email}>
          <Input allowClear placeholder="请输入电子邮箱" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => handleSubmit(form, organizationInfo.org_id)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


UpdateOrganizationLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateOrganizationLayer.defaultProps = {
  onSubmit() { }
};