import { injectable, inject } from "inversify";

import { GlobalUIRender } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";

/**
 * 这是切换页面主题的内聚类
 * **/
@injectable()
export class ThemeService {

  public theme = null;

  constructor(
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService,
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  public async initialize() {
    const { theme } = await this.$UserInfoService.userInfo;
    await this.changeThemeMode(theme || "dark");
  };

  public async changeThemeMode(theme: string) {
    this.theme = theme;
    await this.$GlobalUIRender.executeRender();
  };

  /** 切换到暗黑模式 **/
  public getThemeConfig() {
    if (this.theme === "light") {
      return {
        siderThemeConfig: {
          borderRight: "1px solid rgb(242, 242, 242)"
        },
        headerThemeConfig: {
          borderBottom: "1px solid rgb(242, 242, 242)"
        },
        dateBlockThemeConfig: {
          color: "rgb(51, 51, 51)"
        },
        userInfoBlockThemeConfig: {
          roleTextColor: "rgb(51, 51, 51)",
          orgTextColor: "rgb(102, 102, 102)",
          iconColor: "rgb(51, 51, 51)"
        },
        layoutThemeConfig: {
          Layout: {
            footerBg: "white",
            headerBg: "white",
            siderBg: "white",
            bodyBg: "rgb(237,240,243)"
          }
        },
        subsystemThemeConfig: {
          Menu: {
            darkItemSelectedColor: "rgb(51,51,51)",
            darkItemHoverColor: "rgb(51,51,51,0.6)",
            darkItemSelectedBg: "rgb(232,244,255)",
            darkSubMenuItemBg: "transparent",
            darkItemColor: "rgb(51,51,51)",
            darkItemBg: "transparent",
            itemBg: "transparent"
          }
        },
        systemMenuThemeConfig: {
          Menu: {
            darkItemSelectedColor: "rgb(51,51,51)",
            darkItemHoverColor: "rgb(51,51,51,0.6)",
            darkItemSelectedBg: "rgb(232,244,255)",
            darkSubMenuItemBg: "transparent",
            darkItemColor: "rgb(51,51,51)",
            darkItemBg: "transparent",
            itemBg: "transparent"
          }
        }
      }
    };

    return {
      siderThemeConfig: {
        borderRight: "1px solid rgb(22, 23, 23)"
      },
      headerThemeConfig: {
        borderBottom: "1px solid rgb(22, 23, 23)"
      },
      dateBlockThemeConfig: {
        color: "rgb(255, 255, 255)"
      },
      userInfoBlockThemeConfig: {
        roleTextColor: "rgb(255, 255, 255)",
        orgTextColor: "rgb(255, 255, 255, 0.64)",
        iconColor: "rgb(255, 255, 255, 0.64)"
      },
      layoutThemeConfig: {
        Layout: {
          footerBg: "rgb(22, 23, 23)",
          headerBg: "rgb(22, 23, 26)",
          siderBg: "rgb(22, 23, 26)",
          bodyBg: "rgb(255, 255, 255)"
        }
      },
      subsystemThemeConfig: {
        Menu: {
          darkItemSelectedColor: "rgb(214, 236, 255)",
          darkItemHoverColor: "rgba(0, 119, 222, 0.64)",
          darkItemSelectedBg: "rgba(0, 119, 222, 0.64)",
          darkSubMenuItemBg: "rgb(255, 255, 255)",
          darkItemColor: "rgb(214, 236, 255)",
          darkItemBg: "transparent",
          itemBg: "transparent"
        }
      },
      systemMenuThemeConfig: {
        Menu: {
          darkItemSelectedColor: "rgb(214, 236, 255)",
          darkItemHoverColor: "rgba(0, 119, 222, 0.64)",
          darkItemSelectedBg: "rgba(0, 119, 222, 0.64)",
          darkSubMenuItemBg: "transparent",
          darkItemColor: "rgb(214, 236, 255)",
          darkItemBg: "transparent",
          itemBg: "transparent"
        }
      }
    };
  };

};