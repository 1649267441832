// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BUQLQY66ygRCecoHPSIz {
  position: relative;
}
.mcP8Nk3NSF__oMC0DMP8 {
  overflow-x: hidden;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.oF6teJ3ASVRREh1zRkpO {
  background: #ffffff30;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/OrganizationUserLayout/style.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAEA;EACE,kBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;AAAF;AAGA;EACE,qBAAA;AADF","sourcesContent":[".contentContainer {\n  position: relative;\n}\n\n.contentBlock {\n  overflow-x: hidden;\n  position: absolute;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n}\n\n.scroll {\n  background: #ffffff30;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": `BUQLQY66ygRCecoHPSIz`,
	"contentBlock": `mcP8Nk3NSF__oMC0DMP8`,
	"scroll": `oF6teJ3ASVRREh1zRkpO`
};
export default ___CSS_LOADER_EXPORT___;
