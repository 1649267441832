/* eslint-disable react/prop-types */
import moment from "moment";
import React, { useState, useEffect } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { ThemeService } from "@/services/framework/ThemeService";

function getWeekDay() {
  const basicMoment = moment();
  const formatString = basicMoment.format("YYYY-MM-DD");
  const day = basicMoment.day();
  if (day === 1) {
    return `${formatString} 星期一`;
  };
  if (day === 2) {
    return `${formatString} 星期二`;
  };
  if (day === 3) {
    return `${formatString} 星期三`;
  };
  if (day === 4) {
    return `${formatString} 星期四`;
  };
  if (day === 5) {
    return `${formatString} 星期五`;
  };
  if (day === 6) {
    return `${formatString} 星期六`;
  };
  if (day === 0) {
    return `${formatString} 星期天`;
  };
  return formatString;
};

export function DisplayDate(props) {

  const [displayDate, setDisplayDate] = useState(getWeekDay());

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  useEffect(() => {
    setInterval(() => {
      const weekDay = getWeekDay();
      setDisplayDate(weekDay);
    }, 1000);
  }, []);

  return (
    <div style={{ ...themeService.getThemeConfig().dateBlockThemeConfig, fontSize: 20 }}>
      {displayDate}
    </div>
  )
};


DisplayDate.propTypes = {

};

DisplayDate.defaultProps = {

};