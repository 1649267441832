import "reflect-metadata";
import { Container } from "inversify";

import { UserRoleDictionary } from "@/services/dictionary/UserRoleDictionary";

import { OrganizationManagerService } from "@/services/framework/OrganizationManagerService";
import { DepartmentManagerService } from "@/services/framework/DepartmentManagerService";
import { OrgDeptTreeDataService } from "@/services/framework/OrgDeptTreeDataService";
import { UserManagerService } from "@/services/framework/UserManagerService";
import { OrganizationService } from "@/services/framework/OrganizationService";
import { SubscribeService } from "@/services/framework/SubscribeService";
import { SubSystemService } from "@/services/framework/SubSystemService";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { ThemeService } from "@/services/framework/ThemeService";
import { MenuService } from "@/services/framework/MenuService";
import { ModuleService } from "@/services/framework/ModuleService";

import { RedirectWithRole } from "@/services/router/RedirectWithRole";

export const IOCContainer = new Container();

IOCContainer.bind(OrganizationManagerService).toSelf().inSingletonScope();
IOCContainer.bind(DepartmentManagerService).toSelf().inSingletonScope();
IOCContainer.bind(OrgDeptTreeDataService).toSelf().inSingletonScope();
IOCContainer.bind(UserManagerService).toSelf().inSingletonScope();
IOCContainer.bind(OrganizationService).toSelf().inSingletonScope();
IOCContainer.bind(UserRoleDictionary).toSelf().inSingletonScope();
IOCContainer.bind(SubscribeService).toSelf().inSingletonScope();
IOCContainer.bind(SubSystemService).toSelf().inSingletonScope();
IOCContainer.bind(UserInfoService).toSelf().inSingletonScope();
IOCContainer.bind(ThemeService).toSelf().inSingletonScope();
IOCContainer.bind(MenuService).toSelf().inSingletonScope();
IOCContainer.bind(ModuleService).toSelf().inSingletonScope();

IOCContainer.bind(RedirectWithRole).toSelf().inSingletonScope();
