/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { SelectOrganizationUser } from "@/components/OrganizationUserComponent/SelectOrganizationUser";
import { SelectOrganization } from "@/components/OrganizationUserComponent/SelectOrganization";
import { SelectDepartment } from "@/components/OrganizationUserComponent/SelectDepartment";
import { FloatController } from "@/utils/FloatController";

export function UpdateDepartmentLayer(props) {
  const { departmentInfo, controller, onSubmit } = props;
  const [form] = Form.useForm();

  const [status, setStatus] = useState(false);

  const [selOrgId, setSelOrgId] = useState();

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => {
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  useEffect(() => {
    form.setFieldsValue({
      dept_name: departmentInfo?.dept_name,
      org_id: departmentInfo?.relation_organization?.org_id,
      parent_dept_id: departmentInfo?.parent_department?.dept_id,
    })
  }, [departmentInfo]);


  if (!departmentInfo) {
    return null;
  }

  const org_id = Number(get(departmentInfo, "relation_organization.org_id"));
  const parent_dept_id = Number(get(departmentInfo, "parent_department.dept_id", -1));
  const dept_id = Number(get(departmentInfo, "dept_id", -1));


  const handleFormChange = (changedValues, allValues) => {
    if (changedValues.org_id) {
      setSelOrgId(allValues.org_id);
      form.setFieldsValue({
        parent_dept_id: -1
      })
    }
  }


  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="更新部门信息" onCancel={handleCancel}>
      <Form layout="vertical" form={form} onValuesChange={handleFormChange}>
        <Form.Item label="关联机构" name="org_id" rules={[{ required: true }]} initialValue={org_id}>
          <SelectOrganization />
        </Form.Item>
        <Form.Item label="关联部门" name="parent_dept_id" rules={[{ required: true }]} initialValue={parent_dept_id}>
          <SelectDepartment org_id={selOrgId || org_id} exclude={dept_id} childrenExclude={true} />
        </Form.Item>
        <Form.Item label="部门名称" name="dept_name" rules={[{ required: true }]} initialValue={departmentInfo.dept_name}>
          <Input placeholder="请输入部门名称" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => onSubmit(departmentInfo, form)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


UpdateDepartmentLayer.propTypes = {
  departmentInfo: propTypes.instanceOf(Object),
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateDepartmentLayer.defaultProps = {
  onSubmit() { }
};