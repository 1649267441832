import { injectable, inject } from "inversify";

import { UserInfoService } from "@/services/framework/UserInfoService";

/**
 * 基于用户角色的重定向逻辑
 * **/
@injectable()
export class RedirectWithRole {

  constructor(
    @inject(UserInfoService) private readonly $UserInfoService: UserInfoService
  ) { };

  /**  **/
  public async logoutAndToLoginPage() {
    await this.$UserInfoService.doLogout();
    window.location.href = "/login";
  };

  /** 超管用户的重定向页面 **/
  public async superUserRedirectHref() {
    return "/organization";
  };

  /** 企业用户的重定向页面 **/
  public async organizationUserRedirectHref() {
    const userAssignModules = await this.$UserInfoService.getAssignModuleWithSession();
    return "/main/setting";
  };

  /** 基于当前用户的角色获取默认页面的link_href **/
  public getDefaultPageLinkHref() {
    const { userInfo } = this.$UserInfoService;

    /** 企业用户统一回退到系统设置页面 **/
    return "/main/setting";
  };
};