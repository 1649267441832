/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Flex, Divider, Button, message } from "antd";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { ServiceRuleListTable } from "./components/ServiceRuleListTable";
import { AddProxyRuleLayer } from "./floatlayers/AddProxyRuleLayer";
import { UpdateProxyRuleLayer } from "./floatlayers/UpdateProxyRuleLayer";
import { DeleteProxyRuleLayer } from "./floatlayers/DeleteProxyRuleLayer";
import { FreezeProxyRuleLayer } from "./floatlayers/FreezeProxyRuleLayer";
import { RecoverProxyRuleLayer } from "./floatlayers/RecoverProxyRuleLayer";
import { PageContainer } from "@/components/PageContainer";

import { FloatController } from "@/utils/FloatController";
import { request } from "@/utils/request";

export function ConfigServicesPage(props) {

  const [dataSource, setDataSource] = useState([]);

  /** 获取规则列表 **/
  const fetchDataSource = useCallback(async () => {
    const result = await request({
      method: "GET",
      url: "/api/gmecamp/services/getServiceProxyList"
    });
    setDataSource(result);
  }, []);

  const addProxyRuleController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 新增代理套接规则 **/
  const handleAddProxyRule = useCallback(async (form) => {
    try {
      const result = await form.validateFields();
      message.loading({ key: "handleAddProxyRule", content: "", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/services/addServiceProxyRule",
        data: result
      });
      await addProxyRuleController.close();
      message.success({ key: "handleAddProxyRule", content: "添加成功!", duration: 3 });
      await fetchDataSource();
    } catch (error) {
      message.error({ key: "handleAddProxyRule", content: error.message, duration: 3 });
    };
  }, [fetchDataSource, addProxyRuleController]);

  const updateProxyRuleController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 更新代理套接规则 **/
  const handleUpdateProxyRule = useCallback(async (form, currentRecord) => {
    try {
      const result = await form.validateFields();
      message.loading({ key: "handleUpdateProxyRule", content: "", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/services/updateServiceProxyRule",
        data: { id: currentRecord.service_id, ...result }
      });
      await updateProxyRuleController.close();
      message.success({ key: "handleUpdateProxyRule", content: "更新成功!", duration: 3 });
      await fetchDataSource();
    } catch (error) {
      message.error({ key: "handleUpdateProxyRule", content: error.message, duration: 3 });
    };
  }, [fetchDataSource, updateProxyRuleController]);

  const deleteProxyRuleController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 删除代理套接规则 **/
  const handleDeleteProxyRule = useCallback(async (currentRecord) => {
    try {
      message.loading({ key: "handleDeleteProxyRule", content: "", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/services/deleteServiceProxyRule",
        data: { id: currentRecord.service_id }
      });
      await deleteProxyRuleController.close();
      message.success({ key: "handleDeleteProxyRule", content: "删除成功!", duration: 3 });
      await fetchDataSource();
    } catch (error) {
      message.error({ key: "handleDeleteProxyRule", content: error.message, duration: 3 });
    };
  }, [fetchDataSource, deleteProxyRuleController]);

  const freezeProxyRuleController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 冻结代理套接规则 **/
  const handleFreezeProxyRule = useCallback(async (currentRecord) => {
    try {
      message.loading({ key: "handleFreezeProxyRule", content: "", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/services/freezeServiceProxyRule",
        data: { id: currentRecord.service_id }
      });
      await freezeProxyRuleController.close();
      message.success({ key: "handleFreezeProxyRule", content: "冻结成功!", duration: 3 });
      await fetchDataSource();
    } catch (error) {
      message.error({ key: "handleFreezeProxyRule", content: error.message, duration: 3 });
    };
  }, [fetchDataSource, freezeProxyRuleController]);

  const recoverProxyRuleController = useMemo(() => {
    return new FloatController();
  }, []);

  /** 恢复代理套接规则 **/
  const handleRecoverProxyRule = useCallback(async (currentRecord) => {
    try {
      message.loading({ key: "handleRecoverProxyRule", content: "", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/services/recoverServiceProxyRule",
        data: { id: currentRecord.service_id }
      });
      await recoverProxyRuleController.close();
      message.success({ key: "handleRecoverProxyRule", content: "恢复成功!", duration: 3 });
      await fetchDataSource();
    } catch (error) {
      message.error({ key: "handleRecoverProxyRule", content: error.message, duration: 3 });
    };
  }, [fetchDataSource, recoverProxyRuleController]);

  useEffect(() => { requestAnimationFrame(fetchDataSource) }, [fetchDataSource]);

  return (
    <PageContainer>
      <Flex vertical gap={20}>
        <Divider>该模块用于动态套接服务端</Divider>
        <Flex>
          <Button type="primary" onClick={() => addProxyRuleController.open()}>添加规则</Button>
        </Flex>
        <ServiceRuleListTable
          dataSource={dataSource}
          onUpdateProxyRule={(currentRecord) => updateProxyRuleController.open(currentRecord)}
          onDeleteProxyRule={(currentRecord) => deleteProxyRuleController.open(currentRecord)}
          onFreezeProxyRule={(currentRecord) => freezeProxyRuleController.open(currentRecord)}
          onRecordProxyRule={(currentRecord) => recoverProxyRuleController.open(currentRecord)}
        />
        <AddProxyRuleLayer
          controller={addProxyRuleController}
          onSubmit={handleAddProxyRule}
        />
        <UpdateProxyRuleLayer
          controller={updateProxyRuleController}
          onSubmit={handleUpdateProxyRule}
        />
        <DeleteProxyRuleLayer
          controller={deleteProxyRuleController}
          onSubmit={handleDeleteProxyRule}
        />
        <FreezeProxyRuleLayer
          controller={freezeProxyRuleController}
          onSubmit={handleFreezeProxyRule}
        />
        <RecoverProxyRuleLayer
          controller={recoverProxyRuleController}
          onSubmit={handleRecoverProxyRule}
        />
      </Flex>
    </PageContainer>
  )
};


ConfigServicesPage.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

ConfigServicesPage.defaultProps = {

};