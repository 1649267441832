/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import { useSearchParams } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { SelectOrganizationUser } from "@/components/OrganizationUserComponent/SelectOrganizationUser";
import { SelectDepartment } from "@/components/OrganizationUserComponent/SelectDepartment";
import { FloatController } from "@/utils/FloatController";

export function AddDepartmentLayer(props) {
  const { organizationInfo, departmentInfo, controller, onAddDepartment, onAddDepartmentWithOrgId } = props;
  const [status, setStatus] = useState(false);

  const [searchParams] = useSearchParams();
  const query_org_id = searchParams.get("org_id");
  const query_dept_id = searchParams.get("dept_id");

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler(() => {
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  /**
   * 根据不同类型的查询参数得到的核心业务参数应该是不同的 
   * 如果查询字符串是org_id,那就要按照在企业下添加部门的方式来获取业务参数
   * 如果查询字符串是dept_id,那就要按照基于部门添加部门的方式来获取业务参数
   * **/
  const coreParams = useMemo(() => {
    if (query_dept_id) {
      if (!departmentInfo) {
        return false;
      };
      const org_id = Number(get(departmentInfo, "relation_organization.org_id"));
      const parent_dept_id = Number(get(departmentInfo, "dept_id", -1));
      return { org_id, parent_dept_id };
    };
    if (query_org_id) {
      if (!organizationInfo) {
        return false
      };
      return { org_id: organizationInfo.org_id, parent_dept_id: -1 }
    };
    return false;
  }, [query_org_id, organizationInfo, query_dept_id, departmentInfo]);

  /** 
   * 如果查询字符串的内容是org_id,就表示要调用基于企业添加部门的接口
   * 如果查询字符串的内容是dept_id,就表示要调用在指定部门下添加部门的接口
   * **/
  const handleSubmit = useCallback(async (form) => {
    if (query_org_id) {
      await onAddDepartmentWithOrgId(organizationInfo, form);
    };
    if (query_dept_id) {
      await onAddDepartment(departmentInfo, form);
    };
    return false;
  }, [query_org_id, organizationInfo, onAddDepartmentWithOrgId, query_dept_id, departmentInfo, onAddDepartment]);

  if (!coreParams) {
    return null;
  };

  return (
    <Modal width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="新增部门" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="部门名称" name="dept_name" rules={[{ required: true }]}>
          <Input placeholder="请输入部门名称" />
        </Form.Item>
        <Form.Item label="关联部门" name="parent_dept_id" rules={[{ required: true }]} initialValue={coreParams.parent_dept_id}>
          <SelectDepartment org_id={coreParams.org_id} />
        </Form.Item>
        <Form.Item label="关联用户" name="users">
          <SelectOrganizationUser org_id={coreParams.org_id} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => handleSubmit(form)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


AddDepartmentLayer.propTypes = {
  organizationInfo: propTypes.instanceOf(Object),
  departmentInfo: propTypes.instanceOf(Object),
  controller: propTypes.instanceOf(FloatController),
  onAddDepartmentWithOrgId: propTypes.func,
  onAddDepartment: propTypes.func
};

AddDepartmentLayer.defaultProps = {
  onAddDepartmentWithOrgId() { },
  onAddDepartment() { },
};