/* eslint-disable react/prop-types */
import { Badge } from "antd";
import { BellFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { SubscribeService } from "@/services/framework/SubscribeService";
import { UserInfoService } from "@/services/framework/UserInfoService";

export function SubscribeNotification(props) {

  const navigate = useNavigate();

  const [count, setCount] = useState(0);

  const $SubscribeService: SubscribeService = useDependencieEveryTime(SubscribeService);
  const $UserInfoService = useDependencieEveryTime(UserInfoService);
  const { org_id } = $UserInfoService.userInfo;

  useEffect(() => {
    requestAnimationFrame(async () => {
      await $SubscribeService.startSubscribeListener(org_id, ({ count }) => {
        setCount(count);
      });
    });
    return () => $SubscribeService.clearSubscribeListener();
  }, []);

  /**
   * 调转到告警事件界面
   * **/
  const handleClick = useCallback(() => {
    navigate("/1/event-viewer?handled=0");
  }, [navigate]);

  return (
    <div style={{ width: 50, textAlign: "center", cursor: "pointer" }} onClick={handleClick}>
      <Badge count={count} showZero>
        <BellFilled style={{ fontSize: 24, color: "rgb(164, 179, 193)", verticalAlign: "middle" }} />
      </Badge>
    </div>
  )
};


SubscribeNotification.propTypes = {

};

SubscribeNotification.defaultProps = {

};