/* eslint-disable react/prop-types */
import { get } from "dot-prop";
import propTypes from "prop-types";
import { Space, Button, Table } from "antd";
import { arrayMove } from "@dnd-kit/sortable";
import React, { useMemo, useCallback } from "react";

import { DragableTable, DragableRow } from "@/components/DragableSort";

export function FullModuleList(props) {
  const { dataSource, onModuleSort, onDeployStatic, onChangeModule, onDeleteModule } = props;

  const columns: any[] = useMemo(() => {
    return [{
      key: "module_id",
      align: "center",
      title: "ID",
      width: 60,
      dataIndex: "module_id"
    }, {
      key: "name",
      align: "center",
      title: "模块名称",
      width: 150,
      dataIndex: "name"
    }, {
      key: "rule",
      align: "center",
      title: "匹配规则(相对路径)",
      dataIndex: "rule",
      width: 300,
      render(value, record) {
        if (value) {
          return `/${get(record, "relation_subsystem.subsystem_id", "{{子系统ID}}")}${value}`;
        };
        return "无";
      }
    }, {
      key: "href",
      align: "center",
      title: "调转路径(相对路径)",
      dataIndex: "href",
      width: 300,
      render(value, record) {
        if (value) {
          return `/${get(record, "relation_subsystem.subsystem_id", "{{子系统ID}}")}${value}`;
        };
        return "无";
      }
    }, {
      key: "relation_group",
      align: "center",
      title: "模块分组",
      width: 150,
      dataIndex: "relation_group",
      render(value) {
        if (value) {
          return `${get(value, "group_name", "无")}`;
        };
        return "无";
      }
    }, {
      key: "relation_subsystem",
      align: "center",
      title: "所属子系统",
      width: 150,
      dataIndex: "relation_subsystem",
      render(value) {
        if (value) {
          return (<div>{value.subsystem_name}</div>)
        }
        return (<div style={{ color: "grey" }}>未指定</div>)
      }
    }, {
      key: "resource",
      align: "center",
      title: "mainfast.json资源链接",
      dataIndex: "resource",
      render(value) {
        if (value) {
          return (
            <a href={value}>{value.length > 40 ? `${value.substring(0, 40)}...` : value}</a>
          )
        };
        return (
          <div style={{ color: "grey" }}>无关联资源</div>
        );
      }
    }, {
      key: "operate",
      align: "center",
      title: "操作",
      width: 200,
      render(record) {
        return (
          <Space size={5}>
            <Button type="default" onClick={() => onDeployStatic(record)}>部署静态资源</Button>
            <Button type="primary" onClick={() => onChangeModule(record)}>编辑模块</Button>
            <Button danger type="primary" onClick={() => onDeleteModule(record)}>删除模块</Button>
          </Space>
        )
      }
    }];
  }, [onChangeModule, onDeleteModule]);

  const handleDragEnd = useCallback(async ({ active, over }) => {
    if (!over) {
      return false;
    };
    if (!active) {
      return false;
    };
    if (active.id === over.id) {
      return false;
    };
    const prevDataSource = JSON.parse(JSON.stringify(dataSource));
    const activeIndex = prevDataSource.findIndex((item) => item.module_id === active.id);
    const overIndex = prevDataSource.findIndex((item) => item.module_id === over.id);
    const arrayMoveResult = arrayMove(prevDataSource, activeIndex, overIndex);
    /** 重新计算排序 **/
    const sortableResult = arrayMoveResult.map((everyItemInfo: any, index) => {
      return { module_id: everyItemInfo.module_id, module_pos: index + 1 };
    });
    await onModuleSort(sortableResult);
  }, [dataSource, onModuleSort]);

  return (
    <DragableTable items={dataSource.map((everyItem) => everyItem.module_id)} onDragEnd={handleDragEnd}>
      <Table
        bordered
        rowKey="module_id"
        columns={columns}
        dataSource={dataSource}
        components={{ body: { row: DragableRow } }}
      />
    </DragableTable>
  )
};


FullModuleList.propTypes = {
  onModuleSort: propTypes.func,
  onDeployStatic: propTypes.func,
  onChangeModule: propTypes.func,
  onDeleteModule: propTypes.func,
  onRelationModuleGroup: propTypes.func
};

FullModuleList.defaultProps = {
  dataSource: [],
  onModuleSort() { },
  onDeployStatic() { },
  onChangeModule() { },
  onDeleteModule() { },
};