/* eslint-disable react/prop-types */
import url from "url";
import propTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import React, { useMemo, useCallback } from "react";
import { Flex, Tree, Button, message, TreeDataNode } from "antd";


import { FloatController } from "@/utils/FloatController";
import { loop } from "@/utils/sortableTreeData";
import { request } from "@/utils/request";

import { AddSubSystemFloatLayer } from "../../floatlayers/AddSubSystemFloatLayer";

export function SubSystemTreeData(props) {

  const location = useLocation();

  const { dataSource, onSubSystemSort, onSelectSubSystem, onReloadSubSystemList } = props;

  const { subsystem_id } = useMemo(() => url.parse(location.search, true).query, [location]);

  /** 新增分类 **/
  const addSubSystemController = useMemo(() => {
    return new FloatController();
  }, []);

  const handleAddSubSystem = useCallback(async (form) => {
    try {
      const result = await form.validateFields();
      message.loading({ key: "handleAddSubSystem", content: "正在添加子系统... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/subsystem/addSubSystem",
        data: result
      });
      await onReloadSubSystemList();
      message.success({ key: "handleAddSubSystem", content: "子系统添加成功!", duration: 3 });
      await addSubSystemController.close();
    } catch (error) {
      message.error({ key: "handleAddSubSystem", content: error.message, duration: 3 });
    };
  }, [addSubSystemController, onReloadSubSystemList]);

  /** 选择节点数 **/
  const handleSelect = useCallback((select_system_id_list) => {
    onSelectSubSystem(select_system_id_list[0]);
  }, [onSelectSubSystem]);

  /** 拖拽排序回调函数 **/
  const handleDrop = useCallback(async (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    // the drop position relative to the drop node, inside 0, top -1, bottom 1
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const cloneDataSource = JSON.parse(JSON.stringify(dataSource));

    // Find dragObject
    let dragObj: TreeDataNode;
    loop(cloneDataSource, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(cloneDataSource, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
        item.children.unshift(dragObj);
      });
    } else {
      let ar: TreeDataNode[] = [];
      let i: number;
      loop(cloneDataSource, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i!, 0, dragObj!);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i! + 1, 0, dragObj!);
      }
    };
    const sortableDataSource = cloneDataSource.map((everyPair, index) => {
      const { key, ...otherRveryPair } = everyPair;
      return { subsystem_id: key, ...otherRveryPair, subsystem_pos: index + 1 };
    });
    await onSubSystemSort(sortableDataSource);
  }, [dataSource, onSubSystemSort]);


  if (!dataSource) {
    return null;
  };

  return (
    <Flex vertical gap={20} style={{ width: 200 }}>
      {/** @ts-ignore **/}
      <Button icon={(<PlusOutlined />)} type="primary" onClick={() => addSubSystemController.open()}>添加子系统</Button>
      <Tree
        draggable
        blockNode
        defaultExpandAll
        treeData={dataSource}
        style={{ padding: 20 }}
        onSelect={handleSelect}
        onDrop={handleDrop}
        selectedKeys={[Number(subsystem_id)]}
      />
      <AddSubSystemFloatLayer
        controller={addSubSystemController}
        onSubmit={handleAddSubSystem}
      />
    </Flex>
  )
};


SubSystemTreeData.propTypes = {
  dataSource: propTypes.array,
  onSubSystemSort: propTypes.func,
  onSelectSubSystem: propTypes.func,
  onReloadSubSystemList: propTypes.func
};

SubSystemTreeData.defaultProps = {
  onSubSystemSort() { },
  onSelectSubSystem() { },
  onReloadSubSystemList() { }
};