//@ts-nocheck
/* eslint-disable react/prop-types */
import React from "react";
import { CheckCard } from "@ant-design/pro-components";
// import propTypes from "prop-types";
// import classnames from "classnames";

// import css from "./style.scss";
// import css from "./style.less";

export function SelectTheme(props) {
  return (
    <CheckCard.Group {...props}>
      <CheckCard
        title="暗黑模式"
        avatar={(<div style={{ width: 100, height: "100%", borderRadius: 5, background: "rgb(22, 23, 26)" }} />)}
        description="暗黑模式"
        value="dark"
      />
      <CheckCard
        title="明亮模式"
        avatar={(<div style={{ width: 100, height: "100%", borderRadius: 5, background: "rgb(237, 240, 243)" }} />)}
        description="明亮模式"
        value="light"
      />
    </CheckCard.Group>
  )
};


SelectTheme.propTypes = {

};

SelectTheme.defaultProps = {

};