/* eslint-disable react/prop-types */
import React from "react";
import { Select } from "antd";

import propTypes from "prop-types";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserRoleDictionary } from "@/services/dictionary/UserRoleDictionary";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { isSuper, isDev, isOper } from "@/utils/checkRole";

export function SelectUserLevel(props) {
  const { org_info } = props;
  const userRoleDictionary = useDependencieEveryTime(UserRoleDictionary);
  const { dictionaryCacheTable } = userRoleDictionary;
  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  return (
    <Select {...props} placeholder="请选择用户的权限等级">
      {dictionaryCacheTable.filter(({ label, value }) => {
        if (isSuper(value)) {
          return false;
        }

        if (isDev(value) || isOper(value)) {
          return isSuper(userInfo.role) || userInfo.role === value;
        }

        return true;

      }).map(({ label, value }) => {
        return (
          //@ts-ignore
          <Select.Option key={value} value={value}>{label}</Select.Option>
        )
      })}
    </Select>
  )
};


SelectUserLevel.propTypes = {
  org_info: propTypes.any,

};

SelectUserLevel.defaultProps = {

};