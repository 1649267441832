/* eslint-disable react/prop-types */
import path from "path";
import React, { useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { isUser } from "@/utils/checkRole";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { OrganizationUserLayout } from "@/layouts/OrganizationUserLayout";

import { NotFoundPage } from "@/pages/OrganizationUserPages/NotFoundPage";
import { InitializePage } from "@/pages/OrganizationUserPages/InitializePage";
import { CompanyManagerPage } from "@/pages/OrganizationUserPages/CompanyManagerPage";
import { CustmerSettingPage } from "@/pages/OrganizationUserPages/CustmerSettingPage";
import { SubSystemRedirectPage } from "@/pages/OrganizationUserPages/SubSystemRedirectPage";
// import { DevelopmentPage } from "@/pages/OrganizationUserPages/DevelopmentPage";

import { ResourceComponent } from "@/components/OrganizationUserComponent/ResourceComponent";

import { OrganizationService } from "@/services/framework/OrganizationService";
import { UserRoleDictionary } from "@/services/dictionary/UserRoleDictionary";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { ThemeService } from "@/services/framework/ThemeService";

import { ModuleConfigPage } from "@/pages/OrganizationUserPages/subsystem/ModuleConfigPage";
import { ModuleManagerPage } from "@/pages/OrganizationUserPages/subsystem/ModuleManagerPage";
import { ModuleDeployPage } from "@/pages/OrganizationUserPages/subsystem/ModuleDeployPage";
import { SubSystemConfigPage } from "@/pages/OrganizationUserPages/subsystem/SubSystemConfigPage";
import { ConfigServicesPage } from "@/pages/OrganizationUserPages/microservices/ConfigServicesPage";
import { AssignModulePage } from "@/pages/OrganizationUserPages/CompanyManagerPage/AssignModulePage";

export function OrganizationUserPageEntry(props) {

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo, userAssignModules } = userInfoService;

  const organizationService: OrganizationService = useDependencieEveryTime(OrganizationService);
  const { organizationInfoData, organizationModules } = organizationService;

  const userRoleDictionary: UserRoleDictionary = useDependencieEveryTime(UserRoleDictionary);

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  useEffect(() => {
    requestAnimationFrame(async () => {
      /** 获取到企业的模块配额信息 **/
      await organizationService.getAssignModuleWithOrganization();
      /** 获取到用户的模块配额信息 **/
      await userInfoService.getAssignModuleWithSession();
      /** 加载机构信息 **/
      await organizationService.getOrganizationInfo();
      /** 加载数据字典 **/
      await userRoleDictionary.initialize();
      /** 初始化主题 **/
      await themeService.initialize();
    });
  }, [userInfoService, userRoleDictionary]);

  if (!organizationInfoData) {
    return null;
  };

  if (!organizationModules) {
    return null;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route element={(<OrganizationUserLayout />)}>
          <Route path="/initialize" element={<InitializePage />} />
          {isUser(userInfo.role) ? null : (
            <Route path="/main/company" element={(<CompanyManagerPage />)} />
          )}
          <Route path="/main/setting" element={(<CustmerSettingPage />)} />
          <Route path="/main/module" element={(<ModuleManagerPage />)} />
          <Route path="/main/subsystem" element={(<SubSystemConfigPage />)} />
          <Route path="/main/:subsystem_id/:module_group_id/module" element={<ModuleConfigPage />} />
          <Route path="/main/module/deploy/:module_id" element={<ModuleDeployPage />} />
          <Route path="/main/services" element={(<ConfigServicesPage />)} />
          <Route path="/:sub_system_id" element={(<SubSystemRedirectPage />)} />
          <Route path="/main/assign/:organization_id" element={<AssignModulePage />} />
          {(userAssignModules).map(({ subsystem, module_id, href, rule, resource }) => {
            const firstRoutePath = path.join("/", String(subsystem.subsystem_id), rule);
            if (!href) {
              return null;
            };
            return (
              <React.Fragment key={`${subsystem.subsystem_id}-${module_id}`}>
                <Route path={firstRoutePath} element={(<ResourceComponent remoteResource={resource} />)} />
              </React.Fragment>
            );
          })}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
};


OrganizationUserPageEntry.propTypes = {

};

OrganizationUserPageEntry.defaultProps = {

};