/* eslint-disable react/prop-types */
import { Upload } from "antd";
import React, { useCallback } from "react";
import { InboxOutlined } from "@ant-design/icons";


export function UploadDistDirectory(props) {

  const { value, ...otherProps } = props;

  const handleBeforeUpload = useCallback(async (file) => {
    return false;
  }, []);

  return (
    <Upload.Dragger {...(value || {})} multiple directory beforeUpload={handleBeforeUpload} {...otherProps}>
      <p className="ant-upload-drag-icon">
        {/** @ts-ignore **/}
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        单击(Click)进行上传
      </p>
      <p className="ant-upload-hint">
        先运行npm run build命名,然后将生成后的dist文件夹上传
      </p>
    </Upload.Dragger>
  )
};


UploadDistDirectory.propTypes = {

};

UploadDistDirectory.defaultProps = {

};