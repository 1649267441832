//@ts-nocheck
/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Modal, Form, Input, Button, Flex, Row, Col } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import Icon, { ApartmentOutlined, StepBackwardOutlined } from '@ant-design/icons'
import * as icons from '@ant-design/icons'

import { SelectSubSystem } from "@/components/SuperUserComponent/SelectSubSystem";
import { FloatController } from "@/utils/FloatController";

export function IconSel(props) {
    const { controller, onOk } = props;

    const [status, setStatus] = useState(false);

    /** 注册弹窗句柄 **/
    useEffect(() => {
        controller.registryOpenHandler(() => { setStatus(true) });
        controller.registryConfirmHandler(() => { setStatus(false) });
        controller.registryCancelHandler(() => { setStatus(false) });
        controller.registryCloseHandler(() => { setStatus(false) });
        return () => { controller.clearEffect() }
    }, [controller]);

    const handleCancel = useCallback(async () => {
        await controller.close();
    }, [controller]);

    const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
    const handleIconClick = (iconName: string) => {
        setSelectedIcon(iconName);

    };

    // const iconList = Object.keys(icons);
    // 里面有一些是方法,要筛选一遍,否则页面会报错　　  
    const iconList = Object.keys(icons).filter((item) => {
        return typeof icons[item as keyof typeof icons] === 'object' && item.indexOf('Outlined') > -1;
    })

    return (
        <Modal zIndex={9999} width={1200} destroyOnClose={true} keyboard={false} maskClosable={false} footer={false} open={status} title="选择图标" onCancel={handleCancel}>
            <Form layout="vertical" >
                <div style={{ overflowY: "scroll", height: "600px" }}>
                    <div style={{ padding: '20px' }}>
                        <Row gutter={16} >
                            {iconList.map(item => {
                                const isSelected = selectedIcon === item;
                                return <Col span={3} key={item} style={{
                                    textAlign: 'center', cursor: 'pointer',
                                    backgroundColor: isSelected ? '#1677ff' : 'transparent',
                                }}
                                    onClick={() => handleIconClick(item)}>

                                    <Icon component={icons[item as keyof typeof icons]} style={{ fontSize: '32px', marginRight: '8px', marginTop: '8px' }} />
                                    <p>{item}</p>

                                </Col>
                            })}
                        </Row>
                    </div>
                </div>
                <Form.Item shouldUpdate>
                    {(form) => {
                        return <Button block type="primary" onClick={() => onOk(form, selectedIcon)}>确认</Button>
                    }}
                </Form.Item>
            </Form>
        </Modal>
    )
};


IconSel.propTypes = {
    onOk: propTypes.func,
    controller: propTypes.instanceOf(FloatController),
};

IconSel.defaultProps = {
    onsubmit() { }
};