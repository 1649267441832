// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ucOIc3J3qhzsE0tLWO6k {
  background-position: center center;
  background-size: cover;
  position: relative;
}
.MvbCq2YkEhqjOObsWNDw {
  padding: 50px;
  text-align: left;
  font-size: 72px;
  font-weight: bold;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
}
.hPIYVHv4krEZF3gnESwL {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LoginPage/components/BannerBlock/style.module.less"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,sBAAA;EACA,kBAAA;AACF;AAIA;EACE,aAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,YAAA;EACA,oCAAA;EACA,kBAAA;EACA,QAAA;EACA,OAAA;EACA,QAAA;EACA,6BAAA;AAFF;AAOA;EACE,kBAAA;EACA,aAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;AALF","sourcesContent":[".container {\n  background-position: center center;\n  background-size: cover;\n  position: relative;\n}\n\n;\n\n.content {\n  padding: 50px;\n  text-align: left;\n  font-size: 72px;\n  font-weight: bold;\n  color: white;\n  background-color: rgba(0, 0, 0, 0.6);\n  position: absolute;\n  top: 50%;\n  left: 0;\n  right: 0;\n  transform: translate(0, -50%);\n}\n\n;\n\n.information {\n  position: absolute;\n  bottom: 100px;\n  left: 0;\n  right: 0;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ucOIc3J3qhzsE0tLWO6k`,
	"content": `MvbCq2YkEhqjOObsWNDw`,
	"information": `hPIYVHv4krEZF3gnESwL`
};
export default ___CSS_LOADER_EXPORT___;
