import { injectable, inject } from "inversify";
import { message } from "antd";
import { GlobalUIRender } from "@/commons/ReactIOCContext";

import { FloatController } from "@/utils/FloatController";
import { executeUpload } from "@/utils/executeUpload";
import { request } from "@/utils/request";


@injectable()
export class OrganizationManagerService {

  public organizationInfo: any = null;

  //service和controller耦合，最好拆分
  public updateOrgCapacityController = new FloatController();
  public addOrganizationController = new FloatController();
  public updateOrganizationController = new FloatController();
  public recoverOrganizationController = new FloatController();
  public frozenOrganizationController = new FloatController();
  public delOrganizationController = new FloatController();

  constructor(
    @inject(GlobalUIRender) private readonly $GlobalUIRender: GlobalUIRender
  ) { };

  public async clearOrganizationInfo() {
    this.organizationInfo = null;
    // await this.$GlobalUIRender.executeRender();
  };

  /** 根据树形结构中org_id的切换来加载当前的机构信息 **/
  public async getOrganizationInfo(org_id: number) {
    try {
      // await this.clearOrganizationInfo();
      const organizationInfoData = await request({
        method: "GET",
        url: "/api/gmecamp/org/getOrganizationInfo",
        params: { org_id }
      });
      this.organizationInfo = organizationInfoData;
      await this.$GlobalUIRender.executeRender();
    } catch (error) {
      throw error;
    };
  };


  public async addOrganization(form) {
    try {
      const { logo_id, parent_org_id, ...formFieldResult } = await form.validateFields();
      const logoResource = await executeUpload(logo_id);
      message.loading({ key: "addOrganization", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/org/addOrganization",
        data: {
          ...formFieldResult,
          logo_id: logoResource,
          parent_org_id: parent_org_id === -1 ? null : parent_org_id
        }
      });
      message.success({ key: "addOrganization", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "addOrganization", content: error.message, duration: 3 });
    };
  };

  public async updateOrganization(form, org_id) {
    try {
      const { logo_id, parent_org_id, ...formFieldResult } = await form.validateFields();
      const logoResource = await executeUpload(logo_id);
      message.loading({ key: "updateOrganization", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/org/modifyOrganization",
        data: {
          org_id,
          ...formFieldResult,
          logo_id: logoResource,
          parent_org_id: parent_org_id === -1 ? null : parent_org_id
        }
      });
      message.success({ key: "updateOrganization", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "updateOrganization", content: error.message, duration: 3 });
    };
  };

  public async recoverOrganization(currentRecord) {
    try {
      message.loading({ key: "recoverOrganization", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/org/recoverOrganization",
        data: { org_id: currentRecord.org_id }
      });
      message.success({ key: "recoverOrganization", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "recoverOrganization", content: error.message, duration: 3 });
    };
  };

  public async frozenOrganization(currentRecord) {
    try {
      message.loading({ key: "freezeOrganization", content: "操作中... ...", duration: 0 });
      await request({
        method: "POST",
        url: "/api/gmecamp/org/freezeOrganization",
        data: { org_id: currentRecord.org_id }
      });
      message.success({ key: "freezeOrganization", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "freezeOrganization", content: error.message, duration: 3 });
    };
  };

  public async delOrganization(form, org_id) {
    try {
      message.loading({ key: "delOrganization", content: "操作中... ...", duration: 0 });
      const { password } = await form.validateFields();
      await request({
        method: "POST",
        url: "/api/gmecamp/org/deleteOrganization",
        data: { org_id: org_id, password }
      });
      message.success({ key: "delOrganization", content: "操作成功!", duration: 3 });
    } catch (error) {
      message.error({ key: "delOrganization", content: error.message, duration: 3 });
    };
  };



};