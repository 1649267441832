/* eslint-disable react/prop-types */
import propTypes from "prop-types";
import { Form, Modal, Input, Button } from "antd";
import React, { useState, useEffect, useCallback } from "react";

import { FloatController } from "@/utils/FloatController";

export function UpdateProxyRuleLayer(props) {
  const { controller, onSubmit } = props;

  const [status, setStatus] = useState(false);
  const [ruleInfo, setRuleInfo]: any[] = useState({});

  /** 注册弹窗句柄 **/
  useEffect(() => {
    controller.registryOpenHandler((inputOrganizationInfo) => {
      setRuleInfo(inputOrganizationInfo);
      setStatus(true);
    });
    controller.registryConfirmHandler(() => {
      setRuleInfo({});
      setStatus(false);
    });
    controller.registryCancelHandler(() => {
      setRuleInfo({});
      setStatus(false);
    });
    controller.registryCloseHandler(() => {
      setRuleInfo({});
      setStatus(false);
    });
    return () => { controller.clearEffect() }
  }, [controller]);

  const handleCancel = useCallback(async () => {
    await controller.close();
  }, [controller]);

  return (
    <Modal width={1200} destroyOnClose keyboard={false} maskClosable={false} footer={false} open={status} title="更新组织信息" onCancel={handleCancel}>
      <Form layout="vertical">
        <Form.Item label="命中的规则" name="pattern" rules={[{ required: true }]} initialValue={ruleInfo.pattern}>
          <Input allowClear placeholder="请输入命中的规则" />
        </Form.Item>
        <Form.Item label="目标服务器" name="target_url" rules={[{ required: true }]} initialValue={ruleInfo.target_url}>
          <Input allowClear placeholder="请输入目标服务器" />
        </Form.Item>
        <Form.Item label="规则的简易描述" name="description" rules={[{ required: true }]} initialValue={ruleInfo.description}>
          <Input.TextArea allowClear placeholder="请输入规则的简易描述" />
        </Form.Item>
        <Form.Item shouldUpdate>
          {(form) => {
            return <Button block type="primary" onClick={() => onSubmit(form, ruleInfo)}>确认提交</Button>
          }}
        </Form.Item>
      </Form>
    </Modal>
  )
};


UpdateProxyRuleLayer.propTypes = {
  controller: propTypes.instanceOf(FloatController),
  onSubmit: propTypes.func
};

UpdateProxyRuleLayer.defaultProps = {
  onSubmit() { }
};