import { notification } from "antd";
import { injectable } from "inversify";

import { autoPlayAudio } from "@/utils/autoPlay";
import { request } from "@/utils/request";

import moment from "moment";

type subscribeListenerCallbackType = (param: { list: [], count: number }) => void;

@injectable()
export class SubscribeService {

  private subscribeListenerTimmer: NodeJS.Timeout;

  private unhandledList: [] = [];

  private unhandledCount: number = 0;

  private totalCount: number = 0;

  private eventList: [] = [];

  private lastTick = moment().valueOf();

  /**
   * 监听器的回调函数
   * 目前这里是做简单的处理只使用gpms2的事件轮询接口,后期如果有其他复杂订阅的需求,请考虑单独开发一个管理订阅的后端功能
   * 这里只过滤出未复归的数据handled属性为false的记录
   * 如果发现有未复归的数据就要提示出数量并且告警
   * **/
  private async subscribeCallback(org_id) {
    const data = await request({
      method: "GET",
      url: "/api/gpms2/getBriefEvents",
      params: {
        beginDt: this.lastTick,
        orgId: org_id
      }
    });
    if (data) {
      this.lastTick = moment().valueOf();
      this.totalCount = data.totalEvents;
      this.unhandledCount = data.unhandledEvents;
      this.eventList = data.events;
      if (this.eventList.length) {

        /**
         * 如果发现声音无法正常播放的话请参考这个                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
         * 
         * Chrome浏览器于2018年四月更改了音视频策略，禁止音频和视频的自动播放。Chrome认为自动播放会打扰用户，是否播放应由用户决定。
         * Chrome将通过学习用户的使用习惯来决定哪些网站禁止视频自动播放。在多次手动播放后，Chrome会记住该是可自动播放的网站。
         * （经测试需要20多次点击播放并观看5s后，Chrome会记住您的操作行为）
         * 除Chrome外，其余使用Chrome内核浏览器也会遇到同样情况。
         * 
         * 1.具体政策请参考以下链接
         * @link https://developer.chrome.com/blog/autoplay/
         * 2.或者在浏览器中打开链接
         * @link chrome://settings/content/sound
         * 将当前 域名+端口号 添加至播放白名单
         * **/
        await autoPlayAudio();
        this.eventList.forEach((event: any) => {
          notification.warning({
            message: "提示",
            description: `${moment(event.date).format("YYYY-MM-DD HH:mm:ss")}\n${event.event}`,
            duration: 5,
            style: {
              whiteSpace: 'pre-wrap'
            }
          });
        })
      };
    } else {
      this.totalCount = 0;
      this.unhandledCount = 0;
    }

  };

  /**
   * 你应该在订阅的回调函数内处理组件逻辑,这里会把轮询后产生的相关信息回传
   * 尽量不要在轮询的时候使用GlobalUIRender进行全局更新,会导致组件一直渲染
   * **/
  public async startSubscribeListener(org_id, callback: subscribeListenerCallbackType) {
    clearInterval(this.subscribeListenerTimmer);
    this.subscribeListenerTimmer = setInterval(async () => {
      await this.subscribeCallback(org_id);
      await callback({ list: this.unhandledList, count: this.totalCount });
    }, 10000);
    await this.subscribeCallback(org_id);
    await callback({ list: this.unhandledList, count: this.totalCount });
  };

  public clearSubscribeListener() {
    clearInterval(this.subscribeListenerTimmer);
  };

};