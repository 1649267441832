/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import { Form, Flex, Input, Checkbox, Button, message } from "antd";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";
import { UserInfoService } from "@/services/framework/UserInfoService";

import right_bg from "../../assets/right_bg.png"
import logo from "../../assets/logo.png";

export function LoginFormBlock(props) {

  const [form] = Form.useForm();

  const $UserInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);

  /** 提交登陆 **/
  const handleLogin = useCallback(async () => {
    try {
      const { username, password, expired } = await form.validateFields();
      await $UserInfoService.doLogin({ username, password, expired });
      window.location.reload();
    } catch (error) {
      message.error({ key: "handleClick", content: error.message, duration: 3 });
    };
  }, [form]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.code === "NumpadEnter") {
      handleLogin();
      return false;
    };
    if (event.code === "Enter") {
      handleLogin();
      return false;
    };
  }, [handleLogin]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div style={{ width: "100%", height: "100%", backgroundImage: `url(${right_bg})`, backgroundSize: "100% 100%" }}>
      <Flex style={{ margin: "0px 60px", fontSize: 24, paddingTop: 60 }} align="center">
        <div style={{ width: 202, height: 60, backgroundSize: "cover", backgroundImage: `url(${logo})`, display: "inline-block" }}></div>
        <div style={{ fontSize: 36, padding: "0px 20px", display: "inline-block" }}>/</div>
        <div style={{ display: "inline-block" }}>全球安全能效管理专家</div>
      </Flex>
      <div style={{ margin: "0px 130px" }}>
        <Flex vertical style={{ paddingTop: "20%", paddingBottom: 65, fontSize: 36, fontWeight: "bold" }}>
          <div>新型电力系统综合服务平台</div>
        </Flex>
        <Form form={form} layout="vertical">
          <Form.Item name="username" label="用户名" rules={[{ required: true }]}>
            <Input size="large" placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item name="password" label="登陆密码" rules={[{ required: true }]}>
            <Input.Password size="large" placeholder="请输入登陆密码" />
          </Form.Item>
          <Form.Item>
            <Form.Item noStyle name="expired" valuePropName="checked">
              <Checkbox>7天免登陆</Checkbox>
            </Form.Item>
            <a style={{ float: "right" }}>忘记密码?</a>
          </Form.Item>
          <Button block type="primary" size="large" onClick={handleLogin}>
            登陆运营支撑平台
          </Button>
        </Form>
      </div>
    </div>
  )
};


LoginFormBlock.propTypes = {

};

LoginFormBlock.defaultProps = {

};