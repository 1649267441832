/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import { Flex, Popover, Button, message } from "antd";
import { SmileFilled, CaretDownOutlined } from "@ant-design/icons";

import { useDependencieEveryTime } from "@/commons/ReactIOCContext";

import { UserRoleDictionary } from "@/services/dictionary/UserRoleDictionary";
import { UserInfoService } from "@/services/framework/UserInfoService";
import { RedirectWithRole } from "@/services/router/RedirectWithRole";
import { ThemeService } from "@/services/framework/ThemeService";

export function UserInfoBlock(props) {

  const userRoleDictionary: UserRoleDictionary = useDependencieEveryTime(UserRoleDictionary);
  const { formatDictionaryCacheTable } = userRoleDictionary;

  const userInfoService: UserInfoService = useDependencieEveryTime(UserInfoService);
  const { userInfo } = userInfoService;

  const redirectWithRole: RedirectWithRole = useDependencieEveryTime(RedirectWithRole);

  const themeService: ThemeService = useDependencieEveryTime(ThemeService);

  const handleLogout = useCallback(async () => {
    try {
      await redirectWithRole.logoutAndToLoginPage();
    } catch (error) {
      message.error({ key: "handleLogout", content: error.message, duration: 3 });
    };
  }, []);

  if (!formatDictionaryCacheTable) {
    return null;
  };

  if (!userInfo) {
    return null;
  };

  return (
    <Flex style={{ height: "100%" }} justify="end" align="center">
      <Popover
        trigger="click"
        placement="bottom"
        title={(
          <Flex justify="center">{userInfo.username}</Flex>
        )}
        content={(
          <Flex vertical gap={10}>
            <div style={{ textAlign: "center" }}>
              <div>{`角色权限: ${formatDictionaryCacheTable[userInfo.role]}`}</div>
            </div>
            <Button block danger type="primary" onClick={handleLogout}>
              退出登陆
            </Button>
          </Flex>
        )}
      >
        <Flex>
          <SmileFilled style={{ paddingRight: 8, fontSize: 24, color: "rgb(164, 179, 193)" }} />
          <div style={{ lineHeight: 1.5 }}>
            <div style={{ fontSize: 14, color: themeService.getThemeConfig().userInfoBlockThemeConfig.roleTextColor }}>{formatDictionaryCacheTable[userInfo.role]}</div>
            <div style={{ fontSize: 12, color: themeService.getThemeConfig().userInfoBlockThemeConfig.orgTextColor }}>{userInfo.username}</div>
          </div>
          <CaretDownOutlined style={{ padding: 8, fontSize: 8, color: themeService.getThemeConfig().userInfoBlockThemeConfig.iconColor }} />
        </Flex>
      </Popover>
    </Flex>
  )
};


UserInfoBlock.propTypes = {

};

UserInfoBlock.defaultProps = {

};